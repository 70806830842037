import { deepCopy } from '@firebase/util';
import { collection, query, where } from 'firebase/firestore';
import { firestore } from '../../../../../firebase-config';
import { notificationTypes, useAppSettings } from '../../../../context';
import { IGame, IResponse, IScore, IStanding } from '../../../InterfacesOrTypes';
import { fsPaths, useFirestore } from '../../firestore-repos';
import { useGames } from '../games-repos';

export const useScores = () => {
    const path = fsPaths.scores;
    const {postAsync, updateAllAsync, getByQueryAsync, deleteByWhereConstraintsAsync} = useFirestore();
    const {getAllStartedGamesAsync} = useGames();
    const {handleNotification} = useAppSettings();

    const gameIdQuery = (gameId: string) => {
      return query(collection(firestore, path), where('gameId', '==', gameId));
    }
    const getScoresByGameIdAsync = async (gameId: string) => {
      return await getByQueryAsync<IScore[]>(gameIdQuery(gameId));
    }

    const getScoresByTournamentIdAsync = async (tournamentId: string) => {
      const q = query(collection(firestore, path), where('tournamentId', '==', tournamentId));
      return await getByQueryAsync<IScore[]>(q);
    }

    const getTournamentStandingsAsync = async (tournamentId: string) => {
      const res = await getScoresByTournamentIdAsync(tournamentId);
      // no scores means no played games so return an empty array
      // put in error handling later
      if(!res.success) return [] as IStanding[];

      let teamNames: string[] = [];
      const tournamentScores = res.data as IScore[];
      tournamentScores.forEach(s => {
          s.gamesPlayed = 0;
          if(!teamNames.includes(s.teamName)){
              teamNames.push(s.teamName);
          }
      })
      // return an empty array on no teams 
      if(!teamNames.length) return [] as IStanding[];

      const grouped:IScore[] = [];
      teamNames.forEach(team => {
        const gamesPlayedScores  = tournamentScores.filter(s => s.teamName.toLowerCase() == team.toLowerCase());
        const gamesPlayed = gamesPlayedScores.length;
        let standing = gamesPlayedScores
            .reduce((prev, cur) => {
                  prev.goalsScored = prev.goalsScored + cur.goalsScored;
                  prev.goalsConcede = prev.goalsConcede + cur.goalsConcede;
                  prev.points = prev.points + cur.points;
                  return prev;
              })
              
          Object.assign(standing, {gamesPlayed: gamesPlayed, tie: false})
          grouped.push(standing);
      })

      // grouped has a cumulative scores
      // convert to standings by adding gd
      let nStandings: IStanding[] = grouped.map((s: any) => {
          return ({...s, goalDifferentials: s.goalsScored - s.goalsConcede } as IStanding)
      })

      // Sort standings 
      nStandings = nStandings.sort((a, b) => {
          const t = b.points - a.points || ((b.goalDifferentials || 0) - (a.goalDifferentials || 0));
          if(t == 0) {
            b.tiedWith = a.teamName;
            b.tie = true;
            a.tie = true;
            a.tiedWith = b.teamName;
          }
          // return b.points - a.points || ((b.goalDifferentials || 0) - (a.goalDifferentials || 0))
          return t;
      })
      for (let i = 0; i < nStandings.length; i++) {
        nStandings[i].rank = i + 1;
        // i in the condition is to make sure i'm not starting
        // from zero index
        if(i) {
          const prevStanding = nStandings[i - 1];
          if(prevStanding.tie && nStandings[i].tie && prevStanding.tiedWith === nStandings[i].teamName) {
            nStandings[i].rank = prevStanding.rank;
          }
        }
      }
      
      return nStandings;
    }

    const getGamesScoresAsync = async (): Promise<IResponse<IScore[][]>> => {
      const res = await getAllStartedGamesAsync();
      if(res.success) {
        const games = (res.data as IGame[]);
        const promises:Promise<IResponse<IScore[]>>[] = [];

        games.forEach(game => {
          if(game.id) {
            promises.push(getByQueryAsync<IScore[]>(gameIdQuery(game.id)));
          }
        })

        try {
          const sRes = await Promise.all(promises);
        console.log(sRes);
        // returns as array of iresponse with scores in
        const gamesScores = sRes.map(xRes => {
          return (xRes.data as IScore[])
        })
        const output: IResponse<IScore[][]> = {success: true, exists: true, data: gamesScores};
        return output;
        } catch (error) {
          const output: IResponse<IScore[][]> = {success: false, exists: false, message: (error as any).message};
          return output;
        }
      }

      handleNotification({msg: res.message, type: notificationTypes.error});
      const output: IResponse<IScore[][]> = {success: res.success, exists: res.exists, message: res.message};
      return output;
    }

 
  const adjustScoresAsync = async (oriScore:IScore[], scoreIndex: number, isPos: boolean = true) => {
    const scores = deepCopy(oriScore);
    const output:IResponse<IScore[]> = {success: false, exists: false, data: scores}
    if(!isPos && scores[scoreIndex].goalsScored || isPos) {
        !isPos ? scores[scoreIndex].goalsScored -= 1 : scores[scoreIndex].goalsScored += 1;
        if(scoreIndex) {
            // this means the second score is what was changed
            // need to adjust the score for the other team

            if(scores[scoreIndex].goalsScored > scores[scoreIndex - 1].goalsScored) {
                scores[scoreIndex].points = 3;
                scores[scoreIndex - 1].points = 0;
            } else if(scores[scoreIndex].goalsScored === scores[scoreIndex - 1].goalsScored) {
                scores[scoreIndex].points = 1;
                scores[scoreIndex - 1].points = 1;
            } else {
                scores[scoreIndex - 1].points = 3;
                scores[scoreIndex].points = 0;
            }
            !isPos ? scores[scoreIndex - 1].goalsConcede -= 1 : scores[scoreIndex - 1].goalsConcede += 1;
        } else {
            if(scores[scoreIndex].goalsScored < scores[scoreIndex + 1].goalsScored) {
                scores[scoreIndex + 1].points = 3;
                scores[scoreIndex].points = 0;
            } else if(scores[scoreIndex].goalsScored === scores[scoreIndex + 1].goalsScored) {
                scores[scoreIndex].points = 1;
                scores[scoreIndex + 1].points = 1;
            } else {
                scores[scoreIndex].points = 3;
                scores[scoreIndex + 1].points = 0;
            }
            !isPos ? scores[scoreIndex + 1].goalsConcede -= 1 :  scores[scoreIndex + 1].goalsConcede += 1;
        }
        // scores = scores.sort((a:IScore, b:IScore) => b.points - a.points);
        // console.log(nGames);
      //   setGames(state => ([...nGames]));

      // return scores;
      return await updateScoresAsync(scores, oriScore);
    } else {
      return output;
    }
  }

    const updateScoresAsync = async (scores: IScore[], defaultScores?: IScore[]) => {
      const output:IResponse<IScore[]> = {success: false, exists: false, data: defaultScores}
      const res = await updateAllAsync<IScore>(fsPaths.scores, scores);
      output.success = res.success;
      if(res.success) {
        output.data = scores;
        output.exists = true;
        return output;
      } else {
        output.message = res.message;
        return output;
      };
    }

    const onPostScore = async (gameId: string, teamId: string, tournamentId: string) => {
        const ref = collection(firestore, fsPaths.scores);
        const res = await getByQueryAsync<IScore>(query(ref, 
            where('gameId', '==', gameId),
            where('teamId', '==', teamId),
            where('tournamentId', '==', tournamentId)
          ), false);
        return res.data;
    }

    const postScoreAsync = async (score: IScore) => {
        const data = await onPostScore(score.gameId, score.teamId, score.tournamentId);
        if(data) {
          console.log(data);
          console.log('no need creating a new one');
          return {success: true, exists: true, data: data.id } as IResponse<string>;
        } 
        return await postAsync(path, score);
    }
  
    const deleteScoresByGameIdAsync = async (gameId: string) => {
      return await deleteByWhereConstraintsAsync(path, where('gameId', '==', gameId))
    }

  return { 
    postScoreAsync, 
    adjustScoresAsync, 
    updateScoresAsync,
    getScoresByGameIdAsync, 
    getScoresByTournamentIdAsync, 
    getTournamentStandingsAsync,
    getGamesScoresAsync, 
    deleteScoresByGameIdAsync }
};


 // const adjustScores = (game: any, gameIndex:number, scoreIndex: number, isPos: boolean = true) => {
  //   const nGame = game;
  //   if(!isPos && nGame.scores[scoreIndex].goalsScored || isPos) {
  //       !isPos ? nGame.scores[scoreIndex].goalsScored -= 1 : nGame.scores[scoreIndex].goalsScored += 1;
  //       const nGames = games;
  //       if(scoreIndex) {
  //           // this means the second score is what was changed
  //           // need to adjust the score for the other team

  //           if(nGame.scores[scoreIndex].goalsScored > nGame.scores[scoreIndex - 1].goalsScored) {
  //               nGame.scores[scoreIndex].points = 3;
  //               nGame.scores[scoreIndex - 1].points = 0;
  //           } else if(nGame.scores[scoreIndex].goalsScored === nGame.scores[scoreIndex - 1].goalsScored) {
  //               nGame.scores[scoreIndex].points = 1;
  //               nGame.scores[scoreIndex - 1].points = 1;
  //           } else {
  //               nGame.scores[scoreIndex - 1].points = 3;
  //               nGame.scores[scoreIndex].points = 0;
  //           }
  //           nGame.scores[scoreIndex - 1].goalsConcede += 1;
  //       } else {
  //           if(nGame.scores[scoreIndex].goalsScored < nGame.scores[scoreIndex + 1].goalsScored) {
  //               nGame.scores[scoreIndex + 1].points = 3;
  //               nGame.scores[scoreIndex].points = 0;
  //           } else if(nGame.scores[scoreIndex].goalsScored === nGame.scores[scoreIndex + 1].goalsScored) {
  //               nGame.scores[scoreIndex].points = 1;
  //               nGame.scores[scoreIndex + 1].points = 1;
  //           } else {
  //               nGame.scores[scoreIndex].points = 3;
  //               nGame.scores[scoreIndex + 1].points = 0;
  //           }
  //           !isPos ? nGame.scores[scoreIndex + 1].goalsConcede -= 1 :  nGame.scores[scoreIndex + 1].goalsConcede += 1;
  //       }
  //       nGame.scores = nGame.scores.sort((a:IScore, b:IScore) => b.points - a.points);
  //       nGames[gameIndex] = nGame;
  //       // console.log(nGames);
  //     //   setGames(state => ([...nGames]));
  //   }
  // }

