import { IGame } from "../../InterfacesOrTypes";
import { ShadowCard } from "../cards";
import { GameReadOnly } from "./game";

interface Props {
    games: IGame[];
}

export const GameList: React.FC<Props> = ({games}) => {
    return (
        <div className="games">
            {games.map((game, idx) => {
                return (
                    <ShadowCard key={idx} >
                        <GameReadOnly game={game} className="games" />
                    </ShadowCard>
                )
            })}
        </div>
    )
}