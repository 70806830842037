import { useState } from 'react';
import { UploadInput } from '../../components';
import { IObject } from '../../InterfacesOrTypes';
import './Field.scss'
import { DateField, DateTimeField, DropdownField } from './special';
import { NumberInput, Password, PhoneInput, RadioInput } from './types';
import { DateInput, DateTimeInput } from './types/date';

type ITxtAreaEvt = React.FormEvent<HTMLTextAreaElement>;
type IEvt = React.FormEvent<HTMLInputElement>;


export const fieldTypes = {
    text: 'text',
    password: 'password',
    email: 'email',
    number: 'number',
    phone: 'phone',
    dropdown: 'dropdown',
    date: 'date',
    file: 'file',
    datetime: 'datetime',
    textarea: 'textarea',
    radio: 'radio'
}

export interface IField {
    name: string;
    type?: string;
    init?: string;
    label?: string;
    required?: boolean;
    hidePassword?: boolean;
    dropdown?: {dKey: string; vKey: string; options: IObject[]};
}

export interface IInputResponse {
    name: string; 
    value: string;
}

export interface IInputValue {
    name: string; 
    value: any;
}
export interface ICurrentTarget {currentTarget: {name: string; value: any;}}

export interface IInput {
    id: string; 
    name: string; 
    // value: any; 
    init?: any;
    onChange: (e: IInputValue) => void;
}

export interface IFieldProps {
    field: IField;
    changeHandler: (x: IInputResponse) => void;
    formName: string;
    initObj?: IObject;
}

export const Field: React.FC<IFieldProps> = ({field, formName, initObj = {}, changeHandler }) => {
    const [value, setValue] = useState(initObj[field.name] || '');

    const fieldName = field.name;
    const fieldId = `${formName}_${fieldName}`.toLowerCase();
    const label = field.label || (fieldName as any).splitCamelCaseWordToCommaSeparatedString();
    
    const handler = (e: any) => {
        const val = e.currentTarget.value;
        setValue(val);
        valueHandler({name: fieldName, value: val});
    }

    const valueHandler = (v: IInputValue) => {
        changeHandler(v);
    }


    let input = <input />
    switch(field.type) {
        case fieldTypes.number:
            input = <NumberInput id={fieldId} init={initObj[fieldName]} name={fieldName}  onChange={valueHandler}/>;
            break;
        case fieldTypes.phone:
            input = <PhoneInput init={initObj[fieldName]} id={fieldId} name={fieldName}  onChange={valueHandler}/>;
            break;
        case fieldTypes.textarea:
            input = <textarea id={fieldId} name={fieldName} value={value} onChange={handler} />;
            break;
        case fieldTypes.password:
            input = <Password id={fieldId} hidePassword={field.hidePassword || false } name={fieldName} value={value} onChange={handler} />
            break;
        case fieldTypes.datetime:
            input = <DateTimeInput init={initObj[fieldName]} id={fieldId} name={fieldName} onChange={valueHandler} />
            break;
        case fieldTypes.date:
            input = <DateInput init={initObj[fieldName]} id={fieldId} name={fieldName} onChange={valueHandler} />
            break;
        case fieldTypes.file:
            input = <UploadInput name={fieldName} onSelect={valueHandler} />
            break;
        default:
            input = <input id={fieldId} name={fieldName} value={value} onChange={handler} />;
    }

    return(
        field.type === fieldTypes.dropdown ? <DropdownField options={(field.dropdown as any).options || []} field={field} formName={formName} changeHandler={valueHandler} /> :
        field.type === fieldTypes.radio ? <RadioInput name={fieldName} label={label} onChange={handler} /> :
        field.type === fieldTypes.datetime ? <DateTimeField initObj={initObj} field={field} label={label} changeHandler={valueHandler} id={fieldId} formName={''} /> :
        field.type === fieldTypes.date ? <DateField initObj={initObj} field={field} label={label} changeHandler={valueHandler} id={fieldId} formName={''} /> :

       ( 
            <div className="field text-field">
                <label htmlFor={fieldId}>{label} {field.required && <small>*</small>}</label>
                {input}
            </div>
        )
        
    )
}



