// import { Route, Routes as RRDRoutes } from "react-router-dom"
import { AppRoutes, Route } from "../RoutingModules"
import { Suspense } from "react"
import { urls } from "../../menus";

import {Landing} from "../../../landing";
import { Layout } from "../../../layout";
import { RequireAuth } from "./RequireAuth";
import { UnAuthOnlyRoute } from "./UnAuthOnlyRoute";
import { RequireData } from "./RequireData";
import { ChangeProfilePic, Dashboard, FakePlayerInfo, ForgotPassword, Games, PageNotFound, Player, PlayerLeaderBoard, PlayerRegistration, PlayerScoreBoard, PlayerToTournament, PlayerWaiver, Profile, Redirection, ResetPassword, Rules, SignIn, Standings, Teams, TournamentPaid, TournamentPayment, UnAuthorized, UserEmergencyForm, UserUpdateForm } from "../../../pages";
import { userRoles } from "../../../context";
import { DefaultSpinner } from "../../components";

// function getComponent(comp: string) {
//     return lazy(() => import('../../../pages').then((x:any) => {
//         return  {default: x[comp]};
//     }))
// }

// const Player = getComponent('Player');
// const Dashboard = getComponent('Dashboard');
// const PlayerScoreBoard = getComponent('PlayerScoreBoard');
// const PlayerLeaderBoard = getComponent('PlayerLeaderBoard');
// const Teams = getComponent('Teams');
// const Players = getComponent('Players');
// const Games = getComponent('Games');
// const Standings = getComponent('Standings');



// const TournamentPaid = getComponent('TournamentPaid');
// const TournamentPayment = getComponent('TournamentPayment');


// const UnAuthorized = getComponent('UnAuthorized');
// const Redirection = getComponent('Redirection');
// const PageNotFound = getComponent('PageNotFound');


// const Profile = getComponent('Profile');
// const ChangePasswordForm = getComponent('ChangePasswordForm');
// const UserUpdateForm = getComponent('UserUpdateForm');
// const UserEmergencyForm = getComponent('UserEmergencyForm');
// const ChangeProfilePic = getComponent('ChangeProfilePic');
// const DeleteAccount = getComponent('DeleteAccount');



// const ScoreDetails = getComponent('ScoreDetails');


// const PlayerRegistration = getComponent('PlayerRegistration');
// const PlayerPasscode = getComponent('PlayerPasscode');
// const PlayerWaiver = getComponent('PlayerWaiver');
// const PlayerInfo = getComponent('FakePlayerInfo');
// const PlayerPayment = getComponent('FakePlayerPayment');
// const PlayerPicture = getComponent('PlayerPicture');


export const Routes = () => {
    
    return (
        <Suspense fallback={<DefaultSpinner />}>
            <AppRoutes>
                <Route path="/" element={<Layout />}>
                    {/* <Route path={'/square'} element={<Square />} /> */}
                    
                    {/* Unauthorized routes  */}
                    <Route element={<UnAuthOnlyRoute />}>
                        <Route path={urls.home} element={<Landing/>} />
                        <Route path={urls.signin} element={<SignIn />} />
                        <Route path={urls.forgotpassword} element={<ForgotPassword />} />
                        <Route path={urls.resetpassword} element={<ResetPassword />} />
                    </Route>

                    
                    {/* Player registration */}
                    <Route element={<RequireData />}>
                        <Route path={urls.playerregistration.base} element={<PlayerRegistration />}>
                            {/* <Route index element={<PlayerPasscode />} /> */}
                            {/* <Route path={urls.playerregistration.passcode} element={<PlayerPasscode />} /> */}
                            <Route index element={<PlayerWaiver />} />
                            <Route path={urls.playerregistration.waiver} element={<PlayerWaiver />} />
                            <Route path={urls.playerregistration.info} element={<FakePlayerInfo />} />
                            {/* <Route path={urls.playerregistration.payment} element={<PlayerPayment />} />
                            <Route path={urls.playerregistration.picture} element={<PlayerPicture />} /> */}
                        </Route>
                    </Route>
                    
                    
                    {/* Player Routes  */}
                    <Route element={<RequireAuth allowedRoles={[userRoles.player]} />}>
                        <Route path={urls.player.base} element={<Player />}>
                            <Route index element={<Dashboard />} />
                            <Route path={urls.player.dashboard} element={<Dashboard />} />
                            <Route path={urls.player.teams} element={<Teams />} />
                            <Route path={urls.player.games} element={<Games />} />
                            <Route path={urls.player.scoreboard} element={<PlayerScoreBoard />} />
                            <Route path={urls.player.leaderboard} element={<PlayerLeaderBoard />} />
                            <Route path={urls.player.standings} element={<Standings />} />
                            <Route path={urls.player.rules} element={<Rules />} />
                        </Route>
                    </Route>


                    {/* Profile routes  */}
                    <Route element={<RequireAuth allowedRoles={[
                            userRoles.player
                        ]} />}>
                        <Route path={urls.tournamentpaid} element={<TournamentPaid />} />
                        <Route path={urls.tournamentpayment} element={<TournamentPayment />} />
                        <Route path={urls.playertotournament} element={<PlayerToTournament />} />
                        <Route path={urls.profile.base} element={<Profile />}>
                            <Route index element={<UserUpdateForm />} />
                            <Route path={urls.profile.info} element={<UserUpdateForm />} />
                            <Route path={urls.profile.emergency} element={<UserEmergencyForm />} />
                            {/* <Route path={urls.profile.password} element={<ChangePasswordForm />} /> */}
                            {/* <Route index element={<ChangeProfilePic />} /> */}
                            <Route path={urls.profile.changeprofilepic} element={<ChangeProfilePic />} />
                            {/* <Route path={urls.profile.deleteaccount} element={<DeleteAccount />} /> */}
                        </Route>
                    </Route>

                    
                    {/* Default Routes  */}
                    <Route path={urls.unauthorized} element={<UnAuthorized />} />
                    <Route path={urls.redirection} element={<Redirection />} />
                    <Route path={'*'} element={<PageNotFound />} />
                </Route> 
                {/* app routes ends here */}

            </AppRoutes>
        </Suspense>
    )
}