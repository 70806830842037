import { NavLink } from 'react-router-dom';
import styles from "./buttons.module.scss";

interface Props {
   url: string;
   state?: any;
   className?: string;
   children: any;
}
export const PbuttonLink: React.FC<Props> = ({url, ...props}) => {
 return (
    <NavLink {...props} className={styles.Primary} to={url} />
 );
}

export const SecButtonLink: React.FC<Props> = ({url, ...props}) => {
    return (
        <NavLink {...props} className={styles.Secondary} to={url} />
    );
}


export const SucButtonLink: React.FC<Props> = ({url, ...props}) => {
    return (
        <NavLink {...props} className={styles.Success} to={url} />
    );
}


export const DanButtonLink: React.FC<Props> = ({url, ...props}) => {
    return (
        <NavLink {...props} className={styles.Danger} to={url} />
    );
}


export const AccentButtonLink: React.FC<Props> = ({url, ...props}) => {
    return (
        <NavLink {...props} className={styles.Accent} to={url} />
    );
}
