import React, { useEffect } from 'react';
// import './BackdropBouncyLoader.scss'
import { BouncyLoader } from './BouncyLoader';
import styles from "./loader.module.scss";
export const BackdropBouncyLoader = () => {

useEffect(() => {
    window.scrollTo(0,0);
    document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "scroll";
      }
}, [])

 return (
    <div className={styles.Backdrop} >
        <BouncyLoader />
    </div>
 );
}