import React, { useEffect, useRef } from 'react';
import { IPlayer } from '../../shared';
import { PageLinks } from './pg-links/PageLinks';
import './MainLayout.scss';
import { Outlet } from 'react-router-dom';
import { useLayoutSettings } from './useLayoutSettings';

const useScroll = (ref: any) => {
    const isInViewPort = (elem: any) => {
        var bounding = elem.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };
    useEffect(() => {
        if(ref.current) {
            if ("IntersectionObserver" in window &&
            "IntersectionObserverEntry" in window &&
            "intersectionRatio" in window.IntersectionObserverEntry.prototype
            ) {
                let observer = new IntersectionObserver(entries => {
                    // console.log(entries[0].boundingClientRect.y);
                    const inView = entries[0].isIntersecting;
                    console.log(entries[0].isIntersecting)
                    console.log(entries[0].boundingClientRect.y);
                    if(inView) {
                        ref.current.parentElement.parentElement.classList.add("isSticky");
                    } else {
                        ref.current.parentElement.parentElement.classList.remove("isSticky");
                    }
                    // if (entries[0].boundingClientRect.y < 0) {
                    //     // console.log("Past 100px!");
                    // ref.current.previousElementSibling.classList.add("isSticky");
                    // console.log('inview');
                    // } else {
                    // ref.current.previousElementSibling.classList.remove("isSticky");
                    // //   console.log("Not past 100px");
                    // console.log('NOT');
                    // }
                });
                observer.observe(ref.current);
            }

        }

    }, [ref])
}

export interface IPageLink {
    route: string;
    name: string;
}

interface Props {
    className: string;
    links: IPageLink[];
    excLinks?: string[];
    player?: IPlayer;
    // outlet?:(props: OutletProps) => React.ReactElement | null;
    outlet?: React.ReactElement | null;
    children?: any;
}
export const MainLayout: React.FC<Props> = ({className,links, player, outlet, excLinks = [], children}) => {
    const { header, changeHeader } = useLayoutSettings(links);
    const onProfilePage = className.toLowerCase().includes('profile');
    const ref = useRef<any>();

    // useEffect(() => {
    //     if(ref.current){
    //         const content = document.getElementById('content-root');
    //         const header = content?.getElementsByClassName('mobile-header')[0];
    //         const title = content?.getElementsByClassName('main-layout-page-title')[0];
    //         const top = (window.innerHeight - (header as any).offsetHeight) + ((title as any).offsetHeight || 30);
            
    //         ref.current.style.top = `${top}px`;
    //     }
    // }, [])

    // useEffect(() => {
    //     if(ref.current) {
    //         var productEl = ref.current;// document.querySelector('.product-section')
    //         var stickyEl = document.querySelector('.content-header-menu-and-header')

    //         // check if we have elements
    //         if (productEl && stickyEl) {
    //             console.log('its working')
    //             // create an observer object, binding toggleDisplayFactory to it
    //             var observer = new IntersectionObserver(toggleDisplayFactory(stickyEl), {threshold: 1})
                
    //             // observe the product element
    //             observer.observe(productEl)
    //         }
    //     }
    // }, [])


    // useScroll(ref);

    return (
        <main className={`main-layout ${className}`} >
            <aside>
                <PageLinks links={links} excLinks={excLinks} onHeaderChange={changeHeader} player={player} className="vertical" />
            </aside>
            <div className="content">
                <div className="content-header">
                    {onProfilePage && <>{children}</>}
                    <div className="content-header-menu-and-header">
                        <PageLinks 
                            links={links} 
                            excLinks={excLinks} 
                            onHeaderChange={changeHeader} 
                            player={player} 
                            className="horizontal" />

                        {!onProfilePage && <div className='main-layout-page-title'>
                            <h1>{header}</h1> 
                            <div ref={ref} className="header-watcher">watch</div>
                        </div>}
                    </div>
                </div>

                {outlet ? <>{outlet}</> : player ?  <Outlet context={{ player }} /> : <Outlet />}
            </div>
        </main>
    );
}



// [1] create a named function which accepts an element...
function toggleDisplayFactory(element: any) {
  // and returns a function - the handler that IntersectionObserver expects
  return function handler(entries: any) {
      // determine if the observed element is in the viewport or not
    //   var isInViewPort = Boolean()
    // console.log(entries.find((entry: any) => entry.intersectionRatio > 0))
      var isInViewPort = Boolean(entries.find((entry: any) => entry.intersectionRatio > 0))
      /**
       * if in the viewport:
       *   we want the element passed in at [1] to use it's own styles, i.e. remove the inline style property
       * else:
       *   we want the element to inherit its default display property (e.g. block, inline, inline-block, etc.) and thus be visible
       */
    //   var display = isInViewPort ? null : 'inherit';
    
      // set the display property of the element passed in at [1]
    //   element.style.display = display;
        // element.style.position = isInViewPort ? null : 'fixed';
        const name = 'isSticky';
        // console.log(element.classList)
        isInViewPort ? element.classList.add(name) : element.classList.remove(name);
  }
}