import { insertHTMLPositions } from "../../../utils";

export const useAlert = () => {
    const root = document.getElementById('content-root');
   

    const alertUser =  (msg = "No changes has been made!") => {
        let popup: any;
        root?.insertAdjacentHTML(insertHTMLPositions.firstChildOfAnEle, `
            <div class='alert-wrapper'>
                <div class='alert success-confirm'>
                    <label>${msg}</label>
                    <div class="btns">
                        <button>Ok</button>
                    </div>
                </div>
  
                <style>
                    .alert-wrapper {
                        position: fixed;
                        width: 100%;
                        height: 100%;
                        background: rgba(0,0,0, 0.3);
                        z-index: 1000;
                    }
                    .alert {
                        user-select: none;
                        min-width: 350px;
                        max-width: 400px;
                        background: #fff;
                        border-radius: 6px;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        padding: 30px 30px;
                        box-shadow: 0 2px 5px rgba(0,0,0, 0.2);
  
  
                        z-index: 1000;
                    }
                    
                    .alert .btns {
                        display: flex;
                    }
                    .alert button {
                        width: 100%;
                        margin-top: 50px;
                        border: 0;
                        outline: none;
                        color: #fff;
                        background: #1E2C38;
                        padding: 10px 0;
                        cursor: pointer;
                
                        box-shadow: 0 5px 5px rgba(0,0,0, 0.09);
                    }
  
                </style>
            </div>
           
        `)
        popup = root?.firstElementChild;
        const btnsWrapper = popup.querySelector('.btns');
        const cancel = btnsWrapper.firstElementChild;
        const proceed = btnsWrapper.lastElementChild;
        return new Promise((resolve, _) => {
            proceed.addEventListener('click', () => {
                resolve(true);
                popup.remove();
            });
        })
    }
   
    return { alertUser }
}