import { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { useAppSettings } from '../../../context';
import { insertHTMLPositions } from '../../utils';


export interface IModalSettings {
    onEsc?: boolean;
    onBkdrop?: boolean;
    showCloseBtn?: boolean;
}
interface Props {
    ref?: React.ForwardedRef<any>;
    settings?: IModalSettings;
}
interface IOutput {
    modalRoot: any;
    loading: boolean;
    display: boolean;
    close: () => void;
    settings: IModalSettings;
}
const initSettings: IModalSettings = {
    onEsc: true,
    onBkdrop: false,
    showCloseBtn: true
}
export const useModalFunctions: (x: Props) => IOutput = ({ ref, settings: ms = {} }) => {
   const { loading } = useAppSettings();
   const settings = useRef<IModalSettings>({...initSettings, ...ms}).current;
   const body = (document.querySelector("body") as HTMLElement);
   const content = (document.getElementById('content-root') as HTMLElement);
   
   const keyCodes = useRef<{ESC: number;}>({ESC: 27}).current;
   const [display, setDisplay] = useState(false);
   const [modalRoot, setModalRoot] = useState<any>(content);
  

   const reOrganizePage = () => {
       body.style.overflow = 'hidden';
       (content.firstElementChild?.nextElementSibling as any).style.transition = 'all 0.3s linear';
       const timeout = setTimeout(() => {
           (content.firstElementChild?.nextElementSibling as any).style.filter = 'blur(5px)';
           clearTimeout(timeout);
       }, 50);
   }
   const resetPage = () => {
       body.removeAttribute('style');
       content.firstElementChild?.nextElementSibling?.removeAttribute('style');
   }
   const open = () => {
    content.insertAdjacentHTML(insertHTMLPositions.firstChildOfAnEle, `
        <div class="aw-modal"></div>
    `)
        setModalRoot(content.firstElementChild);
        setDisplay(true);
      reOrganizePage();
   }
  
   const close = () => {
      resetPage();
        modalRoot.remove();
      setDisplay(false);
   }

   const handleClosingKeys = (e: any) => {
       if(e.keyCode == keyCodes.ESC) {
           close();
       }
   }

   useImperativeHandle(ref, () => {
       return {
           open,
           close
       }
   })

   useEffect(() => {
       if(settings.onEsc) document.addEventListener('keydown', handleClosingKeys);
       return () => {
        if(settings.onEsc) document.removeEventListener('keydown', handleClosingKeys);
       }
   },[])
  

  return { modalRoot, loading, display, close, settings }
};