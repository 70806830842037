import React from 'react';
import { IObject } from '../../../../InterfacesOrTypes';
import { IFieldProps } from '../../Field';
import { Dropdown } from '../../types';
import './DropdownField.scss'

interface IDropdwonField extends IFieldProps {
    // field: IField;
    // changeHandler: (x: IInputResponse) => void;
    // formName: string;
    options: IObject[];
}
export const DropdownField: React.FC<IDropdwonField> = ({field, formName, changeHandler, options }) => {
    const fieldName = field.name;
    const fieldId = `${formName}_${fieldName}`.toLowerCase();
    const label = field.label || (fieldName as any).splitCamelCaseWordToCommaSeparatedString();
    
    const handler = (v: any) => {
        changeHandler({name: fieldName, value: v});
    }
    return (
        <div className="text-field">
            <label htmlFor={fieldId}>{label} {field.required && <small>*</small>}</label>
            <Dropdown dKey={field.dropdown?.dKey || ''} vKey={field.dropdown?.vKey || ''} list={options} onSelected={handler} />
        </div>
    )
}