import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { IUserAccount, playerRegKeys, useAppSettings, useAuth, useWebStorage } from '../../../../context';
import { AwError, fieldTypes, Form, FormHeader, IObject, IPlayer, ISegmentedField, ITeam, ITournament, urls, useAlert, usePlayer } from '../../../../shared';
import { userFields, userAccountFields } from '../../../../shared/forms/form-fields';
import { usePlayerReg } from '../usePlayerReg';

export const FakePlayerInfo = () => {
 const {team, tournament} = useOutletContext<{team: ITeam; tournament: ITournament; handleInfo: (values: IObject, player?: IPlayer) => void}>();
 const navigate = useNavigate();
 const {handleLoading} = useAppSettings();
 const [error, setError] = useState<string>('');
 const { alertUser } = useAlert()
 const { playerRegistrationAsync } = usePlayerReg();
 const {setItem} = useWebStorage();
 

const handleSubmit = async (values: any) => {
    handleLoading(true);
    const res = await playerRegistrationAsync(values, tournament.id || "", team.id || "");
    if(res.success) {
        setItem(playerRegKeys.passwordSignin, true);
        navigate(urls.signin);
    } else {
        alertUser(res.message as string);
    }
    handleLoading(false);
 }


 return (
    <div className='player-info' style={{width: '100%'}}>
        {error && <AwError error={error} />}
        <FakeUserForm onSubmit={handleSubmit} />
    </div>
 );
}

interface UserFormProps {
    onSubmit: (v: IUserAccount) => void;
}
export const FakeUserForm: React.FC<UserFormProps> = ({onSubmit}) => {
    const piFields = userFields.filter(x => !x.name.toLowerCase().startsWith('prefer'));
    piFields.push({name: 'profile', label: 'Picture', type: fieldTypes.file, required: true});
    const segFields: ISegmentedField[] = [
        {header: 'Account', fields: userAccountFields},
        {
            header: 'Personal Info',
            fields: piFields
        }
     ]
     
    return (
        <div className='user-form'>
            <FormHeader title='Player Registration' />
            <Form fields={[]} segmentFields={segFields} formName={'player'} onSubmit={onSubmit} /> 
        </div>
    )
}
