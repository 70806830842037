import { useAppSettings } from '../../context';
import { ITournament } from '../../shared';
import './Rules.scss';

export const Rules = () => {
    const {tournament} = useAppSettings();

    // if()
    return (
        <div className='rules'>
            <h1>Tournament Guidelines</h1>

            {getTournamentRules(tournament).map((tRules, idx) => {
                return (
                    <div key={idx}>
                        <h2>{tRules.header}</h2>
                        <ol>
                            {tRules.list.map((rule, id) => <li key={id}>{rule}</li>)}
                        </ol>
                    </div>
                )
            })}
        </div>
    );
}



interface IGameRule {
    header: string;
    list: string[];
 }

const getHtmlFromString = (eleName: any, content: string) => {
    const Ele = eleName;
    // return `&lt;${eleName}&gt;${content}&lt;/${eleName}&gt;`
    return <span dangerouslySetInnerHTML={{ __html: content }} />;
    // return '&lt;strong&gt;${content}&lt;/strong&gt;'
}

const getTournamentRules = (tournament?: ITournament): IGameRule[] => {
    const minNoOfPlayers = tournament ? Math.floor(Number(((tournament as any).gameType as string)[0]) * 0.75) : "4 (for 6v6) and 8 (for 11v11) ";
    const rules: IGameRule[] = [
            {
                header: 'Lateness',
                list: [
                    "All starting players must be dressed up and sign in with the referee before the start of each game. Please be at the field at least 20 minutes before the start time.",
                    'All games start on the times stated on the Match Schedules.',
                    `A minimum of ${minNoOfPlayers} players ${getHtmlFromString('strong','including a goalie if applicable')} can start a game. `,
                    `Every 5 minutes after the game start time will attract a goal for the opposing team. 
                    10 minutes of lateness after the game start time qualifies as a forfeit and the 3 points will go to the waiting team, if they decide to take the forfeit.
                    `
                ]
            },
            {
                header: 'Conduct',
                list: [
                    'Two Yellow Cards in one match is equivalent to one Red Card.',
                    "Any player that gets a Red Card shall leave the field and will not be allowed to play in a subsequent match.",
                    "No team shall walk out of the field no matter the circumstances. Walking out of the field amounts to loss of the game."
                ]
            },
            {
                header: 'General',
                list: [
                    `Grievances during the match must be directed to the Referee by the Team Captain. If the Referees fail to
                    provide redress, the Team Coach may forward the same complaint to the President during half time or after
                    the game.`,
                    "There shall be NO REPLAYS after the Referees have concluded a game.",
                    "All players must respect match officials before, during and after each match.",
                    "Players will be disqualified from the Tournament if they switch teams during the games.",
                    "Players not wearing shin guards and soccer boots will not be allowed to play.",
                    "Fighting, insulting during the game is not allowed. Teams will be disqualified from the tournament if they become rowdy during the game.",
                    "Teams should clean up their area after each game.",
                    "A teams coach is responsible for making sure that all his players are physically fit and of the age to compete."
                ]
            }
    ]
    return rules;
 }
//  export const rules: IGameRule[] = [
//     {
//         header: 'Lateness',
//         list: [
//             'All games start on the times stated on the Match Schedules.',
//             'A minimum of 7 players can start a game.'
//         ]
// }
    // "All starting players must be dressed up and sign in with the ref before the start of the game. Please be at the field at least 20 minutes before the start time",
    // "Every 5 minutes after the game start time will attract a goal for the opposing team",
    // `10 minutes of lateness after the game start time qualifies as a forfeit and the 3 points will go to the waiting team. 
    // The waiting team can decide to take the forfeit or not. The waiting team needs to communicate their decision to the refs.
    // Both teams can decide to play for fun or not.`,
    // "A win is 3 points and a draw is 1 point to each team",
    // "No disrespect to the refs. Insults, threats or any form of harassment towards the refs could result in a yellow card or a red card",
    // "Retaliation on a player could result in a red card so please play professionally",
    // "Red card means a player will miss the next game."
//  ]