import { useState, useEffect } from 'react';

export const useOutsideClicked = (ref: React.MutableRefObject<any>) => {
    const [isOutClicked, setClick] = useState(false);
    
    
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClick(true);
      } else {
        setClick(false);
      }
    }
    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
    
    return { isOutClicked }
};
