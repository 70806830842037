import { FormHeader, Upload } from '../../../shared';


export const ChangeProfilePic = () => {
    const handleComplete = () => {
        console.log('Just finished uploading');
    }

    return (
        <div className="change-profile-img">
            <FormHeader title='Profile Image' />
            <Upload onUploadComplete={handleComplete} isProfile={true} />
        </div>
    );
}