import { useEffect, useState } from "react";
import { useAuth } from "../../context";
import { ALink } from "../components";
import { urls } from "../menus";


interface IBoardingRoutes {name: string; url: string;}

export const useIncompleteAccount = () => {
    const {user} = useAuth();
    const [routes, setRoutes] = useState<IBoardingRoutes[]>([]);
    const detailsToComplete = {
        info: 'info',
        pic: 'profile picture',
        emerg: 'emergency contact'
    }

    const hideFeatures = (): boolean => {
        return !(routes.some(l => l.name === detailsToComplete.emerg));
    }

    const handleRoutes = (data: IBoardingRoutes) => {
        setRoutes(state => {
            const exist = state.filter(r => r.name === data.name);
            if(!exist.length) return ([...state, data]);
            return state;
        })
    }
    useEffect(()=> {
        if(user.isAuthenticated) {
            if(!user.firstName || !user.lastName) {
                handleRoutes({name: detailsToComplete.info, url: urls.profile.info});
            }

            if(!user.profileImageUrl) {
                handleRoutes({name: detailsToComplete.pic, url: urls.profile.changeprofilepic});
            }

            if(!user.emergencyContactMobile || !user.emergencyContactName) {
                handleRoutes({name: detailsToComplete.emerg, url: urls.profile.emergency});
            }
        }
    }, [user])

    const Comp = ({routes: links}: {routes: IBoardingRoutes[]}) => {
        
        return <>
            {links.length > 0 && <p>
                    Please complete your account setup by updating your
                    {links.map((route, idx) => {
                        if(idx > 0) {
                            // it means i have two routes to work with
                            return <span key={idx}>and <ALink url={route.url}> {route.name} </ALink></span>
                        } else {
                            return <ALink key={idx} url={route.url} > {route.name} </ALink>
                        }
                    })}
                </p>
            }
        </>
    }


    return { routes, Comp, hideFeatures }
}