import { useAppSettings } from "../../../context";
import { GameList, ITournament, useGamesRepo } from "../../../shared";

export const Games = () => {
    const {tournament} = useAppSettings();
    const {games} = useGamesRepo(tournament as ITournament);
    return (
       <div>
           <GameList games={games} />
       </div>
    );
   }