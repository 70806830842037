import { useEffect, useState } from 'react';
import { useAppSettings } from '../../../context';
import { useScores } from '../../cus-hook';
import { IStanding } from '../../InterfacesOrTypes';
import './Standing.scss';


export const Standing = () => {
    const [standings, setStandings] = useState<IStanding[]>([]);
    const {tournament} = useAppSettings();
    const {getTournamentStandingsAsync} = useScores();

    useEffect(() => {
        (async function () {
            if(tournament && !standings.length) {
               setStandings(await getTournamentStandingsAsync(tournament.id as string));
            }
        }())
    }, [tournament])
    

 return (
    <div className="standing">
        {standings.length < 0 ? (
            <>
            <div className="table-wrapper">
            <table className='aw-table standings'>
                <thead>
                    <tr>
                        <th style={{textAlign: 'left'}}>Team</th>
                        <th>GP</th>
                        <th>GF</th>
                        <th>GA</th>
                        <th>Pts</th>
                        <th>GD</th>
                        <th>Rks</th>
                    </tr>
                </thead>
            <tbody>
                {(standings).map((standing, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{standing.teamName}</td>
                                <td>{standing.gamesPlayed}</td>
                                <td>{standing.goalsScored}</td>
                                <td>{standing.goalsConcede}</td>
                                <td>{standing.points}</td>
                                <td>{standing.goalDifferentials}</td>
                                <td>{standing.rank}</td>
                            </tr>
                        )
                    })}

            </tbody>
            </table>
        </div>
        <div className="standing-abrevs">
            Notes:
            <div>
                <p>Games Played = GP</p>
                <p>Wins = W</p>
                <p>Losses = L</p>
                <p>Ties = T</p>
                <p>Goals For = GF</p>
                <p>Goals Allowed = GA</p>
                <p>Ranks = Rks</p>
            </div>
        </div>
            </>
        )
        : <h2>No Games Yet!</h2>}
    </div>
 );
}
