import { User } from 'firebase/auth';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { fbStorage } from '../../../../firebase-config';
import { IUser } from '../../../context';
import { IResponse } from '../../InterfacesOrTypes';
import { fsPaths, useFirestore } from '../firestore-repos';

export const fbStoragePaths = {
    profiles: 'profiles'
}


export const useFbStorage = () => {
  // const {updateDocAsync} = useFirestore();
    
  const uploadAsync = async (file: any, path: string, user: any, setLoading: any): Promise<IResponse<string>> => {
    let output: IResponse<string> = {success: false, exists: true};
    try {
      setLoading(true);
      const fileName = file.name;
      
      const pathStr = `${path}/${user.uid}/${fileName}`;//`${path}/${user.uid}.${fileName.substring(fileName.lastIndexOf('.') + 1)}`
      const fileRef = ref(fbStorage, pathStr);
      await uploadBytes(fileRef, file)
      const imgUrl = await getDownloadURL(fileRef);
      output.data = imgUrl;
      output.success = true;
      setLoading(false);
      return output;
    } catch (error) {
        output.message = (error as any).message;
        output.exists = false;
      setLoading(false);
      return output;
    }
  }

  const uploadFileWithProgress = (file: any, path: string, user: User | IUser, setLoading: any, setProgress: (n: number) => void): Promise<IResponse<string>> => {
    let output: IResponse<string> = {success: false, exists: true};
    return new Promise((resolve, reject) => {
      const fileName = file.name;
      // const ext = `${fileName.substring(fileName.lastIndexOf('.') + 1)}`;
      // const pathStr = `${path}/${user.uid}/${fileName}.${ext}`;
      const pathStr = `${path}/${user.uid}/${fileName}`;
      const storageRef = ref(fbStorage, pathStr);
      const upload = uploadBytesResumable(storageRef, file);
      upload.on('state_changed', (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
      }, (error) => {
        // in case of error
          output.message = (error as any).message;
          output.exists = false;
          setLoading(false);
          reject(error);
      }, async () => {
        // completed successfully
        try {
          const imgUrl = await getDownloadURL(storageRef);
          output.data = imgUrl;
          output.success = true;
          setLoading(false);
          return resolve(output);
        } catch (error) {
          output.message = (error as any).message;
          output.exists = false;
          setLoading(false);
          return reject(output);
        }
      })
    })
  }
  

  // const runAsyncInLoop = async (arr: any, ids: string[]) => {
  //   const userIdAndImgUrlPromises: any = [];
  //   for (let i = 0; i < arr.length; i++) {
  //     const urlRes = await listAll(arr[i]);
  //     userIdAndImgUrlPromises.push(getDownloadURL(urlRes.items[0]))
  //   }

  //   const o = await Promise.all(userIdAndImgUrlPromises);

  //   for (let i = 0; i < ids.length; i++) {
  //     const userId = ids[i];
  //     const userUrl = o.filter(url => url.includes(userId))[0];
  //     if(userUrl) {
  //       await updateDocAsync(fsPaths.users, userId, {profileImageUrl: userUrl});
  //       console.log(userUrl)
  //     }
  //   }
    


  // }
  // const getAllFilesAsync = async (path: string) => {
  //   try {
  //     const imgRefs = ref(fbStorage, path);
  //     const res = await listAll(imgRefs);
  //     const userProfileRefs = res.prefixes.map(imgRef => ({[imgRef.name]: ref(fbStorage, `${path}/${imgRef.name}`)}));
  //     const userIds = userProfileRefs.map(obk => Object.keys(obk)[0]);
  //     const userImgRefs = userIds.map((id, idx) => userProfileRefs[idx][id]);
  //     const userIdAndImgUrl: any = [];
  //     const userIdAndImgUrlPromises: any = [];
  //     await runAsyncInLoop(userImgRefs, userIds);
  //     // userImgRefs.forEach(async (item, idx) => {
  //     //   const urlRes = await listAll(item);
  //     //   console.log(urlRes);
  //     //   // urlRes.items.map(imgRef => getDownloadURL(imgRef));
  //     //   userIdAndImgUrlPromises.push(getDownloadURL(urlRes.items[0]))
  //     // })
  //     // console.log(userIdAndImgUrlPromises);
  //     // const o = await Promise.all(userIdAndImgUrlPromises);

  //     // console.log(o);
  //     // console.log(userIdAndImgUrl);


  //     // const refUser = await listAll(userProfileRefs[0]);
  //     // console.log(refUser);
  //     // const rePromise = refUser.items.map(imgRef => getDownloadURL(imgRef));
  //     // console.log(rePromise);
  //     // const o = await Promise.all(rePromise);
  //     // console.log(o);
  //   } catch (error) {
  //     const msg = (error as any).message;
  //     console.log(msg)
  //   }
  // }

  const deleteFileAsync = async (path: string, setLoading?: any): Promise<IResponse<boolean>> => {
    let output: IResponse<boolean> = {success: false, exists: true};
    try {
      if(setLoading) setLoading(true);
      const imgRef = ref(fbStorage, path);
      await deleteObject(imgRef);
      output.data = true;
      output.success = true;
      if(setLoading) setLoading(false);
      return output;
    } catch (error) {
      output.message = (error as any).message;
      output.exists = false;
      if(setLoading) setLoading(false);
      return output;
    }
  }

  return { uploadAsync, uploadFileWithProgress, deleteFileAsync }
};


