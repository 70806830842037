import { forwardRef } from 'react';
import ReactDOM  from 'react-dom';
import { Pbutton, SecButton } from '../btns';
import { Close } from '../icons';
import { BouncyLoader } from '../loaders';
import styles from "./modal.module.scss";
import { IModalSettings, useModalFunctions } from './useModalFunctions';



export interface IFormModal {
    ref: any;
    title: string;
    onSubmit: () => void;
    children?: any;
    settings?: IModalSettings;
    style?: any;
}
interface IMFRef {
    current: {
        open: () => void;
        close: () => void;
    }
}
export const FormModal = forwardRef<any, IFormModal>(({title, onSubmit, settings: ms, children, style={}}, ref) => {
    const {modalRoot, loading, display, close, settings } = useModalFunctions({ref, settings: ms});

    if(!display) return null;

   
    return ReactDOM.createPortal(
       <>
       { loading && <BouncyLoader /> }
       
        <div className={styles.AwModal}  role="dialog" aria-labelledby="dialog-title">
            <div className={styles.AwModalBackdrop} onClick={settings.onBkdrop ? close : () => null} />
            
            <div className={styles.AwModalContent} style={{...style}}>
                <div className={styles.AwModalHeader}>
                    <h1 id="dialog-title">{title}</h1>
                    {settings.showCloseBtn && <Close className='white' clicked={close} />}
                </div>
                <div className={styles.AwFormModalBody}>
                        {children}
                </div>
                <div className={styles.AwModalFooter}>
                        <SecButton clicked={close} label="Cancel" />
                        <Pbutton clicked={onSubmit} label="Submit" />
                </div>
                
            </div>
        </div>
       </>
        , modalRoot  as HTMLElement)
   })
