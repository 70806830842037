import { useEffect, useState } from 'react';
import { useAppSettings } from '../../../context';
import { usePlayer } from '../../cus-hook';
import { IPlayerGameStat } from '../../InterfacesOrTypes';
// import './LeaderBoard.scss'

export const LeaderBoard = () => {
    const {getLeaderBoardAsync} = usePlayer();
    const {tournament} = useAppSettings();
    const [board, setBoard] = useState<IPlayerGameStat[]>([]);

    useEffect(() => {
        (async function(){
            if(tournament) {
                const res = await getLeaderBoardAsync(tournament.id as string);
                setBoard(res.data as IPlayerGameStat[]);
            }
        }())
    }, [tournament])


    return (
        <div className='leader-board'>
            {board.length > 0 ? (
                <table className='aw-table leader-board'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Team</th>
                        <th>Goals</th>
                        <th>Rks</th>
                    </tr>
                </thead>
                <tbody>
                    {board.map((lead, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{lead.name}</td>
                                <td>{lead.teamName}</td>
                                <td>{lead.count}</td>
                                <td>{lead.rank}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            ) 
            : 
            <h2>No Games Yet!</h2>}
        </div>
    );
}
