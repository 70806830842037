export interface IMenu {
    url: string;
    name: string;
    children: IMenu[] | any;
}

export const urls = {
    home: "/",
    playerregistration: {
        base: '/playerregistration',
        passcode: '/playerregistration/passcode',
        waiver: '/playerregistration/waiver',
        info: '/playerregistration/info',
        payment: '/playerregistration/payment',
        picture: '/playerregistration/picture'
    },
    player: {
        base: '/player',
        dashboard: "/player/dashboard",
        games: '/player/games',
        teams: '/player/teams',
        scoreboard: '/player/scoreboard',
        standings: '/player/standings',
        rules: '/player/rules',
        leaderboard: '/player/leaderboard'
    },
    signin: "/signin",
    forgotpassword: "/forgotpassword",
    resetpassword: "/resetpassword",
    unauthorized: '/unauthorized',
    redirection: '/notallowed',
    pagenotfound: '/404',
    tournamentpaid: '/tournamentpaid',
    tournamentpayment: '/tournamentpayment',
    playertotournament: '/p2t',
    profile: {
        base: '/profile',
        info: '/profile/info',
        emergency: '/profile/emergency',
        password: '/profile/password',
        changeprofilepic: '/profile/profilepic',
        deleteaccount: '/profile/deleteaccount'
    },
    admin: {
        base: '/admin',
        dashboard: '/admin/dashboard',
        standings: '/admin/standings',
        leaderboard: '/admin/leaderboard',
        
        scoreboard: '/admin/scoreboard',
        scoreboardDetails: '/admin/scoreboard/:gameid',

        scoremgt: '/admin/scoremgt',
        updatescore: '/admin/scoremgt/:gameid',

        teams: {base: '/admin/teams', create: '/admin/teams/new'},
        players: '/admin/players',
        // playeredit: '/admin/players/:playerid',
        games: {base: '/admin/games', create: '/admin/games/new', reschedule: '/admin/games/:gameid/rescheduling',  refs: '/admin/games/refs'},
        referees: '/admin/referees',
        tournaments: {base: '/admin/tournaments', create: '/admin/tournaments/new'},
        addresses: {base: '/admin/addresses', create: '/admin/addresses/new'}
    },

    referee: {
        base: '/ref',
        dashboard: '/ref/dashboard',
        games: '/ref/games',
        scoregame: '/ref/games/:gameid'
    }
}

export const menuList: IMenu[] = [
    {url: urls.home, name: 'Home', children: null},
    {url: urls.signin, name: 'Sign In', children: null},
    {url: urls.player.base, name: 'Dashboard', children: null},
    
    {url: '', name: 'Admin', children: [
        {url:  urls.admin.base, name: 'Dashboard', children: null},
        {url: urls.admin.tournaments.base, name: 'Tournament', children: null},
        {url: urls.admin.teams.base, name: 'Teams', children: null}
    ]}
    
];
