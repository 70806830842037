import { useState } from 'react';
import { Form, FormHeader, IObject, loginFields } from '../../../shared';
import styles from "../auth.module.scss";
// import './ResetPassword.scss'

export const ResetPassword = () => {
    const [fields] = useState([loginFields[0]]);

    const handleSubmit = (vals: IObject) => {
        console.log(vals);
    }
    return (
        <div className={styles.AuthPageContainer}>
            <div className={styles.AuthFormContainer}>
                <FormHeader title='Reset Password'/>
                <Form fields={fields} formName={'reset password'} onSubmit={handleSubmit} />
            </div>
        </div>
    );
}