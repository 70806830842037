// import './TournamentPaid.scss'

import { useAppSettings, useAuth } from "../../../context";
import { ALink, IPlayer, urls, usePlayer } from "../../../shared";
import tick from "../../../../assets/icons/tick.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { registrationPages } from "../../registration";

export const TournamentPaid = () => {
    const {user} = useAuth();
    const {tournament} = useAppSettings();
    const navigate = useNavigate();
    const {readPlayerAsync, updatePlayerAsync} = usePlayer();
    const [searchParams] = useSearchParams();


    const {transactionid, orderid} = useMemo(() => {
        return Object.fromEntries([...(searchParams as any)])
    }, [searchParams])
    console.log(transactionid);
    console.log(orderid );

    const markPaidAsync = async (player: IPlayer) => {
        player.isPaid = true;
        if(user.profileImageUrl) {
            player.completedStage = registrationPages.picture;
            player.completed = true;
        } else {
            player.completedStage = registrationPages.payment;
        }
        const res = await updatePlayerAsync(tournament?.id as string, player);
        if(res.success) {
            if(player.completed) return navigate(urls.player.dashboard);
            return navigate(urls.profile.changeprofilepic);
        } else {
            // do something if update fails 
        }
    }

    useEffect(() => {
        (async function() {
            console.log(transactionid);
            if(user) {
                const res = await readPlayerAsync(tournament?.id as string);
                if(res.success) {
                    markPaidAsync(res.data as IPlayer)
                }
            }
    
        }())
        return () => {}
    }, [user, tournament])
    return (
        <div style={{textAlign: 'center',
            lineHeight: 2}}>
            <img src={tick} alt="" style={{width: '100px'}}/>
            <p>Your payment went through successfully!</p>
            {!user.profileImageUrl && <p>Please add your player picture <ALink url={urls.profile.changeprofilepic}> here.</ALink></p>}
        </div>
    );
}
