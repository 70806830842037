import React, { useMemo } from 'react';
import { ALink, IPlayer, urls, useLocation } from '../../../shared';
import { IPageLink } from '../MainLayout';
import './PageLinks.scss';


interface Props {
    className: string;
    links: IPageLink[];
    excLinks?: string[];
    player?: IPlayer;
    onHeaderChange: (header: string) => void;
}
export const PageLinks:React.FC<Props> = ({className, links, excLinks = [], player, onHeaderChange}) => {
    const filteredLinks = useMemo(() => {
        if(!excLinks.length) return links;

        // if(!player && excLinks.length) return links;


        // if(!player || (!player.isPaid && !player.isWaived)) {
        //     const lks = excLinks.map(str => str.toLowerCase());
        //     return links.filter(link => !lks.includes(link.name.toLowerCase()));
        // }
        const lks = excLinks.map(str => str.toLowerCase());
        return links.filter(link => !lks.includes(link.name.toLowerCase()));
        // return links;
    }, [player])

    const location = useLocation();
    
    const handleClick = (name: string) => {
        onHeaderChange(name);
    }

    return (
        <div className={`page-links ${className}`}>
            {filteredLinks.map((item, idx) => {
                    return (
                        <div key={idx} onClick={() => handleClick(item.name)}>
                            {location.pathname === urls.player.base && item.route === urls.player.dashboard ? <ALink  className="active" url={item.route}>{item.name}</ALink>
                            : <ALink  url={item.route} >{item.name}</ALink>}
                        </div>
                    )
                })}
        </div>
    );
}