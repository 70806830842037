import React from 'react';
import { ITeam } from '../../InterfacesOrTypes';
import { DanButton } from '../btns';
import { ShadowCard } from '../cards';
import './TeamsPresentation.scss'

interface Props {
    teams: ITeam[];
    showDetails?: boolean;
    action?: {label: string, clicked: (team: ITeam, index?: number) => void};
}
export const TeamsPresentation: React.FC<Props> = ({teams, showDetails = false, action}) => {
 return (
    <div className="teams">
        {teams.map((team, idx) => {
            return (
                <ShadowCard key={idx} className="team">
                    <h2>{team.name}</h2>
                    {showDetails && (
                        <div>
                            <p>Passcode: <strong>{team.passcode}</strong></p>
                            <p>Coach: <strong>{team.coach}</strong></p>
                            <p>Contact: <strong>{(team.mobile as any).phone()}</strong></p>
                            {action && <DanButton label={action.label} clicked={() =>  action.clicked(team, idx)} />}
                        </div>
                    )}
                </ShadowCard>
            )
        })}
    </div>
 );
}
