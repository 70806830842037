import { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ITeam, ITournament, Pbutton, SecButton, urls, useAlert } from '../../../../shared';
import './PlayerWaiver.scss'


export const PlayerWaiver = () => {
   const {team, tournament, handleWaiver} = useOutletContext<{team: ITeam; tournament: ITournament, handleWaiver: () => void}>();
   const [show, setShow] = useState();
   const navigate = useNavigate();

   const {alertUser} = useAlert();

   const handleScroll = (e:any) => {
      const {scrollTop, scrollHeight, clientHeight } = e.target;
      const topAndBottomPaddingAdjustment = 15;
      const adjustedHeight = scrollHeight - topAndBottomPaddingAdjustment;
      if ((scrollTop + clientHeight) >= adjustedHeight) {
         
      }
   }
   const handleReject = () => {
      alertUser('Thanks for your interest in joining us! Goodbye!');
      navigate(urls.home);
   }
   const handleAccept = () => {
      handleWaiver();
      navigate(urls.playerregistration.info, {state: {data: {team, tournament}}});
   }

   return (
      <div className='player-waiver'>
         <div style={{color: 'red'}}>Scroll Down on the waiver to see all terms and conditions!</div>
         <div className="waiver-text-wrap" onScroll={handleScroll}>
            <p>
            I understand that there are risks associated with playing all sports and field related
                    activities. In consideration for the privilege to participate in all LEAPSTEP Foundation sporting activities, my acceptance of this document indicates that I assume the risk of any injuries that I may sustain while participating in any activity. I insure that I am physically
                    and mentally able to participate in any of LEAPSTEP's sporting activities.
                    I give permission for trainers and coaches or contracted health care to start
                    preliminary treatment and arrange transportation for me to a local Emergency
                    Room in the event that I become ill or injured.
                    I understand that NO REFUNDs will be given under any circumstances whatsoever including, but not limited to, any game cancellations, any game forfeitures, suspension for misconduct, either individually or as a team, injuries received, moving, or any other event that might take place at any time wherein I do not complete any given season. I agrees not to stop payment on any check, submit a charge-back on any credit card, or request a refund of any kind for any reason whatsoever as none will be given except in the sole and only discretion of, and in an amount determined solely by, the management of LEAPSTEP foundation by a check issued to me.
                    I give permission for the use of my name, likeness, image, voice, and/or appearance as such may be embodied in any pictures, photos, video recordings, audiotapes, digital images, and the like, taken or made on behalf of LEAPSTEP foundation and the activities of LEAPSTEP foundation. I agree that LEAPSTEP foundation has complete ownership of such pictures, etc., including the entire copyright, and may use them for any purpose consistent with the programs and activities of LEAPSTEP foundation. These uses include, but are not limited to illustrations, bulletins, exhibitions, videotapes, reprints, reproductions, publications, advertisements, and any promotional or educational materials in any medium now known or later developed, including the Internet. I acknowledge that I will not receive any compensation, etc. for the use of such pictures, etc., and hereby releases LLEAPSTEP foundation and its agents and assigns from any and all claims which arise out of or are in any way connected with such use.

                    By electronically checking off this Waiver and Liability Agreement, I acknowledge that 
                    <strong>  I HAVE READ AND FULLY UNDERSTAND AND AGREE TO ALL OF ITS TERMS AND CONDITIONS INCLUDING PERMISION TO TREAT AGREEMENT
                    </strong>
                    . I further state
                    that I have executed this waiver and liability voluntarily and with full knowledge of its
                    significance to be binding on me, my heirs, executors, administrators and assigns.
            </p>
         {/* LET'S PLAY SOCCER AT GARDNER VILLAGE
         VOLUNTARY RELEASE-WAIVER-ASSUMPTION OF RISK
         AND FULL INDEMNITY AGREEMENT
         The undersigned participant hereinafter referred to as the “undersigned” requests and is granted the revocable permission to enter upon the premises of Let's Play Soccer at Gardner Village, dba Let's Play Soccer at Gardner Village, hereinafter referred to as “Owner”, to participate as a player or a coach in said soccer games and/ or matches, and/or camps, and/or practices, and/or scrimmages, and/or other activities that may be conducted within the Owner’s premises. Said entry shall hereinafter be referred to as “revocable permissive entry”.

         In consideration of the “revocable permissive entry”, onto the premises of Owner, the undersigned of himself/herself, all personal representatives executors, executrixes, heirs next of kin, spouse, and assigns do hereby agree as follows:

         The undersigned do hereby FULLY RELEASE, FULLY WAIVE, FULLY DISCHARGE, AND COVENANT NOT TO SUE, the operators or Owner, its officers, directors, shareholders, employees, agents, managers, attorneys, subsidiaries, independent contractors, successor or assigns, (hereinafter collectively referred to as “Releasees”) and each of them, from any and all claims, and/or the ordinary negligence of Releasees, and/or the strict liability, and/or the negligence of any third party or participant, which causes the undersigned injury, death, personal injury, bodily injury, property damages, or any other type or kind of injury, and/or loss, and/or damages. The undersigned hereby covenants to hold Releasees harmless from and to fully indemnify Releasees for any type or kind of damages, judgements, awards, or related expenses (including but not limited to Releasees’ attorney fees and court costs) that Releasees may incur as a result of the participation of the undersigned in any activity, and/or game, and/or match, and/or practices, and/or camps, and/or scrimmages, and/or leagues, conducted within Owner’s premises at any time.
         The undersigned verifies that he/she is physically fit and sufficiently trained to play and compete in Owner’s leagues, camps, practices, games, scrimmages, matches and other activities and that the undersigned’s physical condition for participation in Owner’s leagues, camps practices, games, scrimmages, matches, and other activities has been verified by a licensed medical doctor during the last six months. The undersigned further agrees and warrants that if at any time the undersigned believes conditions to be unsafe, or if at any time the undersigned’s health suffers, the undersigned will immediately discontinue participation, and leave if appropriate. The undersigned authorizes Owner, its managers or representatives to carry out emergency medical transport or medical care for the undersigned, as may be necessary in Owner’s sole discretion. The undersigned verifies that he/she has and shall maintain sufficient medical insurance to cover any and all medical, expenses that may arise from the undersigned’s participation in the Owner’s leagues, or other activities and undersigned does not, and shall not rely upon or look towards Owner and/ or the Releasees. The undersigned understands that it is the undersigned’s responsibility to comply with all posted and published procedures, including safety and hygiene procedures and protocols intended to lessen the likelihood of the spread of disease among participants and staff. The undersigned further understands that it is the undersigned’s responsibility to comply with all laws, regulations, public health orders and requirements imposed by federal, state and local authorities.
         The undersigned ACKNOWLEDGES that there is INHERENT DANGER in the participation in any and all soccer activities including but not limited to games, matches, practices, camps, scrimmages, and other activities, which the undersigned appreciates and voluntarily assumes including potential exposure to communicable disease, (including but not limited to coronavirus/COVID-19, other viruses, bacteria, and all other infectious pathogens and disease vectors.) The undersigned has inspected the Owner’s premises and THE UNDERSIGNED VOLUNTARILY ELECTS TO ACCEPT ALL RISKS CONNECTED WITH HIS/HER PARTICIPATION IN ALL SOCCER ACTIVITIES, AS DESCRIBED ABOVE. THE UNDERSIGNED HAS READ THIS DOCUMENT CAREFULLY AND FULLY UNDERSTANDS EACH AND ALL OF ITS TERMS. THE UNDERSIGNED UNDERSTANDS THAT IT IS A RELEASE OF ALL CLAIMS. THE UNDERSIGNED UNDERSTANDS THAT HE/SHE ASSUMES ALL RISKS INHERENT IN ALL SOCCER PARTICIPATION. THE UNDERSIGNED VOUNTARILY SIGNS HIS/HER NAME EVIDENCING HIS/HER ACCEPTANCE OF THE ABOVE PROVISIONS.
         If a provision of this agreement is held to be illegal or invalid by a court of competent jurisdiction, such provisions shall be considered severed and deleted. Such severance and deletion shall not affect the validity of the remaining provisions of this agreement, which shall remain in full force and effect.
         This Voluntary Release-Waiver-Assumption of Risk and Full Indemnity Agreement shall be in full force and effect at all times during undersigned’s participation in the soccer activities, of any kind or nature, and for whatever period of the time the undersigned participates in said activities, either continuously or from time to time, as the case may be.
         Should any dispute arise pertaining to the terms of this agreement, or should any action be filed or commenced to enforce the terms of this agreement, the prevailing party shall be entitled to recover its reasonable attorney fees and costs, whether or not such dispute proceeds to judgment.
         It is specifically understood and agreed by the undersigned that NO REFUNDs will be given under any circumstances whatsoever including, but not limited to, any game cancellations, any game forfeitures, suspension for misconduct, either individually or as a team, injuries received, moving, or any other event that might take place at any time wherein the undersigned does not complete any given season. The undersigned hereby specifically agrees not to stop payment on any check, submit a charge-back on any credit card, or request a refund of any kind for any reason whatsoever as none will be given except in the sole and only discretion of, and in an amount determined solely by, the management of this facility by a check issued to the undersigned.
         The undersigned give permission to use the undersigned’s name, likeness, image, voice, and/or appearance as such may be embodied in any pictures, photos, video recordings, audiotapes, digital images, and the like, taken or made on behalf of LET’S PLAY SPORTS, INC. and the activities of LET’S PLAY SPORTS, INC. The undersigned agrees that LET’S PLAY SPORTS, INC. has complete ownership of such pictures, etc., including the entire copyright, and may use them for any purpose consistent with the programs and activities of LET’S PLAY SPORTS, INC. These uses include, but are not limited to illustrations, bulletins, exhibitions, videotapes, reprints, reproductions, publications, advertisements, and any promotional or educational materials in any medium now known or later developed, including the Internet. The undersigned acknowledges that the undersigned will not receive any compensation, etc. for the use of such pictures, etc., and hereby releases LET’S PLAY SPORTS, INC. and its agents and assigns from any and all claims which arise out of or are in any way connected with such use. */}

         </div>
         <div className='btn-div'>
            <SecButton label='Reject' clicked={handleReject} />
            <Pbutton label={'Accept And Sign'} clicked={handleAccept} />
         </div>
      </div>
   );
}
