import React from 'react';
// import './BouncyLoader.scss'
import styles from "./loader.module.scss";

export const BouncyLoader = () => {
    return (
        <div className={styles.BouncyLoader}>
            <div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}