import React from 'react';
import { GoogleIcon } from '../../../../assets';
import styles from "./buttons.module.scss";

interface Props {
    label: string;
    loading?: boolean;
    style?: any;
    clicked: () => void;
    children?: any;
}
export const Pbutton: React.FC<Props> = ({label, loading = false, clicked, ...props}) => {
    // const handleClicked = () => {
    //     if(loading) {

    //     }
    // }
    return (
        <button disabled={loading} className={styles.Primary} {...props} onClick={clicked}>{label}</button>
    );
}

export const SecButton: React.FC<Props> = ({label, clicked, ...props}) => {
    return (
        <button className={styles.Secondary} {...props}  onClick={clicked}>{label}</button>
    );
}


export const SucButton: React.FC<Props> = ({label, clicked, ...props}) => {
    return (
        <button className={styles.Success}  {...props} onClick={clicked}>{label}</button>
    );
}

export const DanButton: React.FC<Props> = ({label, clicked, ...props}) => {
    return (
        <button className={styles.Danger} {...props} onClick={clicked}>{label}</button>
    );
}

export const WarnButton: React.FC<Props> = ({label, clicked, ...props}) => {
    return (
        <button className={styles.Warning} {...props} onClick={clicked}>{label}</button>
    );
}



export const AccentButton: React.FC<Props> = ({label, clicked, ...props}) => {
    return (
        <button className={styles.Accent} {...props}   onClick={clicked}>{label}</button>
    );
}
interface IBtn extends Props {
    className?: string;
}
const Button: React.FC<IBtn> = ({label, loading = false, clicked, ...props}) => {
    return <button disabled={loading} className={styles.Primary} {...props} onClick={clicked}>{props.children} {label}</button>
}

export const GoogleButton: React.FC<Props> = ({...props}) => {
    return <Button {...props} className={styles.GoogleBtn}><GoogleIcon /></Button>
}

