import React from "react";
import { IUser, useAppSettings, useAuth } from "../../context";
import { ALink, fieldTypes, IEmailForm, IObject, IResponse, ModalWithForm, sendEmailAsync, ShadowCard, useAlert } from "../../shared";


interface Props {
    amount: number;
    children?: any;
    handlePaymentAsync: (v: IObject, user: IUser) => Promise<IResponse<IEmailForm>>;
}

export const Payment: React.FC<Props> = ({amount, handlePaymentAsync}) => {
    const {user, rootUrl, navigate} = useAuth();
    const { handleLoading } = useAppSettings();
    const { alertUser } = useAlert();

    const handleSubmit = async (v: IObject, close: any) => {
        handleLoading(true);
        
        const pRes = await handlePaymentAsync(v, user);
        if(!pRes.success) {
            handleLoading(false);
            return alertUser(pRes.message);
        }


        const res = await sendEmailAsync(pRes.data as IEmailForm);
        handleLoading(false);
        if(res.success) {
            close();
            await alertUser('Thank you for making your payment. As soon as your payment is received we will change your account details.');
            navigate(rootUrl);
        } else {
            alertUser('Our system is down. Please try again later!');
        }
    }

    return (
        <div className="payment">
            {/* {count == 1 && children.type === 'header' && <>{children}</>} */}
            <h2 style={{color: '#aaa'}}>You can make your payment of <strong style={{color: 'black'}}>${amount}</strong> via any of the methods below.</h2>
            {/* <ShadowCard>
                <h2>Credit Card Payment</h2>
                <p style={{color: 'red'}}>There might be a 2% - 3% charge with credit card payment.</p>
                <a href="https://square.link/u/QUwW3YII" rel="noreferrer">Make Payment</a>
            </ShadowCard> */}
            <ShadowCard style={{lineHeight: 1.8}}>
                <h2>Payment Methods</h2>
                {/* <p style={{color: 'red'}}>Please copy and paste - <strong>{user.uid}</strong> - on your payment note or details. </p> */}
                <p style={{color: 'red'}}>Please add your <strong style={{color: 'black'}}>first</strong> and <strong style={{color: 'black'}}>last name</strong>  or the <strong style={{color: 'black'}}>name of the person</strong> you are paying for as a note on the payment description. </p>
                <p><ALink url={'https://venmo.com/code?user_id=2662040468455424007&created=1660592569.770993&printed=1'} >venmo: @tnterprise</ALink></p>
                <p><ALink url={'https://cash.app/$Tadwoa'} >cashapp: $Tadwoa </ALink></p>

                <ModalWithForm onSubmit={handleSubmit} fields={[
                    {name: 'method', label: 'Which payment method did you use?', type: fieldTypes.dropdown, dropdown: {
                        dKey: 'name',
                        vKey: "name",
                        options: [{name: 'Venmo'}, {name: 'Cashapp'}]
                    }}
                ]} title={'Payment Confirmation'} btnLabel={'Paid ?'} {...{style:{width: '400px'}}}>
                </ModalWithForm>
            </ShadowCard>
            {/* <p>Zelle: {(contacts.main.mobile as any).phone()} </p> */}
            {/* {count == 1 && children.type === 'footer' && <>{children}</>}
            {count == 2 && children[1].type === 'footer' && <>{children[1]}</>} */}
        </div>
    );
}