import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { IObject, IPlayer, ITeam, ITournament, urls, usePlayer } from '../../../shared';
import './PlayerRegistration.scss';

interface IReg {loading: boolean; 
    passcode: boolean; waiver: boolean; info: IObject; 
    picture: string;
    player?: IPlayer;
    stepper: number;
}
export interface IRegState {team: ITeam; tournament: ITournament; player?: IPlayer}
export const registrationPages = {
    code: "Team Code",
    waiver:  "Waiver",
    info: "Info",
    payment: "Payment",
    picture: "Picture"
}
export const PlayerRegistration = () => {
    // const [playerState, setPlayerState] = useState<{loading: boolean; player?: IPlayer;}>({loading: true});
    const [registration, setRegistration] = useState<IReg>({
        loading: true, 
        passcode: false, 
        waiver: false, 
        info: {},
        picture: '', 
        stepper: 1 
    });
    const {readPlayerAsync} = usePlayer();

    const location = useLocation();
    const navigate = useNavigate();

    const {team, tournament} = useMemo(() => {
        const data = location.state ? ((location.state as any).data as IRegState) : {} as IRegState;
        return data;
    }, [])
    
    

    const steppers = [
        // {path: urls.playerregistration.passcode, name: registrationPages.code},
        {path: urls.playerregistration.waiver, name: registrationPages.waiver},
        {path: urls.playerregistration.info, name: registrationPages.info}
        // {path: urls.playerregistration.payment, name: registrationPages.payment},
        // {path: urls.playerregistration.picture, name: registrationPages.picture}
    ]
    const handlePasscode = () => {
        setRegistration(state => ({...state, passcode: true, stepper: 2}));
    }

    const handleWaiver = () => {
        setRegistration(state => ({...state, waiver: true, stepper: 3}));
    }

    const handleInfo = (values: IObject, player?: IPlayer) => {
        setRegistration(state => ({...state, player: player, info: values, stepper: 4}));
    }

    const handlePicture = (img: string) => {
        setRegistration(state => ({...state, picture: img}));
    }

    useEffect(() => {
        (async function(){
            const res = await readPlayerAsync(tournament.id || "");
            if(res.success && res.data) {
                setRegistration(state => ({...state, loading: false, player: res.data}));
            } else {
                setRegistration(state => ({...state, loading: false}))
            }
        }())
        return () => { }
    }, [])


    return (
        <div className='player-registration'>
            <div className='step-indicators'>
                {steppers.map((step, idx) => {
                    return (
                        <div className={`${(step.path === location.pathname || (step.path === urls.playerregistration.waiver && location.pathname === urls.playerregistration.base)) ? 'active' : ''}`} key={idx}>
                            <span>{idx + 1}</span> {step.name} 
                        </div>
                    )
                })}
            </div>
            <Outlet context={{registration, handleWaiver, handleInfo, team, tournament}}  />
            {/* <div className='step-indicators'>
                {steppers.map((step, idx) => {
                    return (
                        <div className={`${(step.path === location.pathname || (step.path === urls.playerregistration.passcode && location.pathname === urls.playerregistration.base)) ? 'active' : ''}`} key={idx}>
                            <span>{idx + 1}</span> {step.name} 
                        </div>
                    )
                })}
            </div>
            <Outlet context={{registration, handlePasscode, handleWaiver, handleInfo, handlePicture, team, tournament}} /> */}
        </div>
    );
}

