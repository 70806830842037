import { collection, onSnapshot, query, QueryConstraint, Unsubscribe, where } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { firestore } from '../../../../../firebase-config';
import { useAppSettings } from '../../../../context';
import { IGame, IObject, IScore } from '../../../InterfacesOrTypes';
import { fsPaths, useFirestore } from '../../firestore-repos';

export const useLiveScoresRepo = () => {
  const {tournament} = useAppSettings();
  const {getDocsFromSnapshot} = useFirestore();
  const [gameIds, setGameIds] = useState<string[]>([]);
  const [scores, setScores] = useState<IScore[][]>([])

  // const getLiveGameScoresAsync = async (): Promise<IResponse<IScore[][]>> => {
  //   const res = await getAllStartedGamesAsync();
  //   if(res.success) {
  //     const games = (res.data as IGame[]);
  //     const promises:Promise<IResponse<IScore[]>>[] = [];

  //     games.forEach(game => {
  //       if(game.id) {
  //         promises.push(getByQueryAsync<IScore[]>(gameIdQuery(game.id)));
  //       }
  //     })

  //     try {
  //       const sRes = await Promise.all(promises);
  //     console.log(sRes);
  //     // returns as array of iresponse with scores in
  //     const gamesScores = sRes.map(xRes => {
  //       return (xRes.data as IScore[])
  //     })
  //     const output: IResponse<IScore[][]> = {success: true, exists: true, data: gamesScores};
  //     return output;
  //     } catch (error) {
  //       const output: IResponse<IScore[][]> = {success: false, exists: false, message: (error as any).message};
  //       return output;
  //     }
  //   }

  //   handleNotification({msg: res.message, type: notificationTypes.error});
  //   const output: IResponse<IScore[][]> = {success: res.success, exists: res.exists, message: res.message};
  //   return output;
  // }
  

  const getQueries = (path: string, ...qc: QueryConstraint[]) => {
    return query(collection(firestore, path), ...qc);
  }
  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if(gameIds.length) {
      const clauses: QueryConstraint = where('gameId', 'in', gameIds);
      unsubscribe = onSnapshot(getQueries(fsPaths.scores, clauses), (snapshots) => {
        const sc: IScore[] = getDocsFromSnapshot(snapshots);
        setScores(Object.values(sc.reduce((prev, cur: any) => {
          ((prev as any)[cur.gameId] = (prev as any)[cur.gameId] || []).push(cur);
            return prev;
        }, {})));
        
      })
    }
    
    return () => { if(unsubscribe) unsubscribe(); };
  }, [gameIds]);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if(tournament) {
      const clauses = [where('isStarted', '==', true), where('isPlayed', '==', false)];
      unsubscribe = onSnapshot(getQueries(fsPaths.games, ...clauses), (snapshots) => {
        const games: IGame[] = getDocsFromSnapshot(snapshots);
        if(games.length) setGameIds(games.map(game => game.id as string));
      })
    }
    return () => { if(unsubscribe) unsubscribe(); };
  }, [tournament]);

  return { scores }
};

