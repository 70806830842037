import { collection, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import { useEffect } from 'react';
import { firestore } from '../../../../../firebase-config';
import { useAppSettings, useAuth } from '../../../../context';
import { IGame, IObject, IResponse, IScore, ITeam } from '../../../InterfacesOrTypes';
import { hasGoals } from '../../../utils';
import { fsPaths, useFirestore } from '../../firestore-repos';
import { useScores } from '../scores-repos';

export const useGames = () => {
  const {user} = useAuth();
  const {tournament} = useAppSettings();

  const {readAsync, getByQueryAsync, postAllAsync, updateDocAsync, updateAllAsync, deleteByWhereConstraintsAsync} = useFirestore();
  
  // delete
  
  // const [, ] = useState();

  const updateGameAsync = async (gameId: string, change: IObject) => {
    return await updateDocAsync(fsPaths.games, gameId, change);
  }
  const updateGamesAsync = async (games: IGame[]) => {
    const saveableGames = [...games];
    return await updateAllAsync<IGame>(fsPaths.games, saveableGames);
  }
  
  const getGameModel = (homeTeam: ITeam, guestTeam: ITeam): IGame => {
    return {
      label: `${homeTeam.name} vs ${guestTeam.name}`, 
      guest: guestTeam.name, 
      guestTeamId: guestTeam.id || '',
      home: homeTeam.name,
      homeTeamId: homeTeam.id || '',
      tournamentId: tournament?.id || '',
      gameDate: tournament?.startDate || new Date(),
      uid: user.uid,
      isStarted: false,
      signInStarted: false,
      isCancelled: false,
      isPlayed: false,
      refIds: []
    }
  }

  const getGameByIdAsync = async (gameId: string) => {
    return await readAsync<IGame>(fsPaths.games, gameId);
  }

    const getAllGamesAsync = async (isAll = false) => {
        if(!tournament) {
            return {success: true, data: [], exists: false} as IResponse<IGame[]>;
        }

        const qc: QueryConstraint[] = [where('tournamentId', '==', tournament?.id)];
        if(!isAll) {
            qc.push(where('isPlayed', '==', false));
        }
        const q = query(collection(firestore, fsPaths.games), 
        ...qc,
        orderBy('gameDate')
        );
        const res = await getByQueryAsync<IGame[]>(q);
        return res;
    }

    const getRefGamesAsync = async (userId?: string) => {
        const q = query(collection(firestore, fsPaths.games), 
        where('tournamentId', '==', tournament?.id), 
        where('refIds', 'array-contains', userId || user.uid));
        const res = await getByQueryAsync<IGame[]>(q);
        return res;
    }

    const getAllStartedGamesAsync = async () => {
        if(!tournament) {
            return {success: false, data: [], exists: false, message: 'No value for current tournament'} as IResponse<IGame[]>;
        }
        const q = query(collection(firestore, fsPaths.games), where('tournamentId', '==', tournament?.id), where('isStarted', '==', true));
        const res = await getByQueryAsync<IGame[]>(q);
        return res;
    }


   const allPlayAllSetup = (teams: ITeam[]) => {
        let games: IGame[] = [];
        for (let i = 0; i < teams.length; i++) {
            const homeTeam = teams[i];
            for (let j = i; j < teams.length; j++) {
                const guestTeam = teams[j + 1];
                if(guestTeam) {
                    games.push(getGameModel(homeTeam, guestTeam));
                } else {
                    break;
                }
            }
        }
        games = games.sort(() => Math.random() - 0.5);

        return games;
    }

    const twoGamesFourTeams = (teams: ITeam[]): IGame[] => {
        const games: IGame[] = [];
        for (let i = 0; i < (teams.length - 2); i++) {
            const homeTeam = teams[i];
            for (let j = 0; j < 2; j++) {
                // address should be added to the games
                // this will help with showing the venue for the games
                // there should also be a way to order the games according to date time
                const guestTeam = teams[(teams.length - 1) - j]
                if(guestTeam) {
                    games.push(getGameModel(homeTeam, guestTeam));
                } else {
                    break;
                }
            }
        }
        return games;
    }

    const postAllGamesAsync = async (games: IGame[]) => {
        return await postAllAsync<IGame>(games, fsPaths.games);
    }

    
    const startGameSignInAsync = async (gameId: string) => {
        const changeObj = {signInStarted: true};
        const res = await updateDocAsync(fsPaths.games, gameId, changeObj);
        let output: IResponse<IObject> = {...res as any};
        if(res.success) {
            output.data = changeObj;
        }
        return output;
    }

    const markGameAsStartedAsync = async (gameId: string) => {
        const changeObj = {isStarted: true, startTime: new Date()};
        const res = await updateDocAsync(fsPaths.games, gameId, changeObj);
        let output: IResponse<IObject> = {...res as any};
        if(res.success) {
            output.data = changeObj;
        }
        return output;
    }

    const onGameChanges = async (gameId: string) => {
        const ref = collection(firestore, fsPaths.scores);
        const res = await getByQueryAsync<IScore[]>(query(ref, where('gameId', '==', gameId)));
        return res;
    }
    const onGameCancelled = async (gameId: string) => {
        let output: IResponse<boolean> = {success: false, exists: false, message: 'Cannot cancel this game due to system error!'};
        const res = await onGameChanges(gameId);
        if(res.success && res.data) {
            const scores = res.data;
            // there are already goals in the game and we cannot delete the scores
            if(!hasGoals(scores)) {
                const res = await deleteByWhereConstraintsAsync(fsPaths.scores, where('gameId', '==', gameId));
                console.log('no need deleting the scores');
                output = res;
                return output;
            }
        }
        return output; 
    }

    const finishGame = async (gameId: string, obj: IObject) => {
        const changeObj = {isPlayed: true, endTime: new Date(), ...obj};
        const res = await updateDocAsync(fsPaths.games, gameId, changeObj);
        let output: IResponse<IObject> = {...res as any};
        if(res.success) {
            output.data = changeObj;
        }
        return output;
        
    }
    const cancelGameAsync = async (gameId: string, notes: string) => {
        // delete the scores of the game
        const res = await onGameCancelled(gameId);
        // add note to the game
        if(res.success) {
            const uRes = await finishGame(gameId, {
                isStarted: false, 
                signInStarted: false,
                isPlayed: false, 
                endTime: null, 
                startTime: null,
                isCancelled: true, 
                notes: notes
            });
            return uRes;
        }
        return {success: res.success, exists: false, message: res.message} as IResponse<IObject>;
    }

    const endGameAsync = async (gameId: string) => {
        return await finishGame(gameId, {});
    }

    const forfeitGameAsync = async (gameId: string) => {
        return await finishGame(gameId, { isForfeit: true });
    }


    const resetGameForTestingAsync = async (gameId: string) => {
        // await deleteScoresByGameIdAsync(gameId);
        return await finishGame(gameId, { isPlayed: false, signInStarted: false, isStarted: false, startTime: null, isForfeit: false });
    }
    

    useEffect(() => {
        
        return () => {
        };
    });

  return { 
    getGameByIdAsync,
    getAllGamesAsync, 
    getRefGamesAsync,
    getAllStartedGamesAsync, 
    postAllGamesAsync, 
    twoGamesFourTeams, 
    allPlayAllSetup, 
    markGameAsStartedAsync,
    startGameSignInAsync,
    endGameAsync,
    forfeitGameAsync,
    resetGameForTestingAsync,
    cancelGameAsync,
    updateGameAsync,
    updateGamesAsync
    }
};