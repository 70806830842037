import { useState } from "react";
import { HideIcon, ShowIcon } from "../../../../../../assets";

import './Password.scss'


interface Props {
    id: string;
    hidePassword: boolean;
    name: string;
    value: any;
    onChange: any;
}
export const Password: React.FC<Props> = ({hidePassword = false, ...props}) => {
    const [show, setShow] = useState(false);

    return (
        <div className="password-field">
            <input {...props} type={show ? 'text' : 'password'} />
            {!!props.value && !hidePassword && (
                <div className="icon-div" onClick={() => setShow(!show)}>
                    {show ? <ShowIcon /> : <HideIcon />}
                </div>
            )}
        </div>
    )
}