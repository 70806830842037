import { createUserWithEmailAndPassword, signOut, updateProfile, User, UserCredential } from 'firebase/auth';
import { useState, useEffect } from 'react';
import { auth } from '../../../../firebase-config';
import { IUser, IUserAccount } from '../../../context';
import { castReturnValue, fbStoragePaths, fsPaths, IPlayer, IResponse, useFbStorage, useFirestore, usePlayer } from '../../../shared';
import { registrationPages } from './PlayerRegistration';

export const usePlayerReg = () => {
    const { postWithCustomIDAsync, updateDocAsync } = useFirestore();
    const { uploadAsync } = useFbStorage();
    const { postPlayerAndReturnIUserAsync } = usePlayer();


    const playerIUserRegAsync = async (v: IUserAccount, fUser: User) => {
        // Create and add IUser
        const user: IUser = {
            uid: fUser.uid,
            firstName: v.firstName,
            lastName: v.lastName,
            emergencyContactName: v.emergencyContactName || "",
            emergencyContactMobile: v.emergencyContactMobile || "",
            preferredName: v.preferredName || "",
            dob: v.dob,
            mobile: v.mobile,
            email: v.email,
            isAdmin: false,
            waiverSigned: true,
            waiverSignedDate: new Date(),
            createdBy: 'self',
            isCoach: false,
            roles: ['player']
        }
        return  await postWithCustomIDAsync<IUser>(fsPaths.users, user.uid, user);
    }

    const playerRegistrationAsync = async (v: IUserAccount, tournamentId: string, teamId: string) => {
        try {
            // Register User
            const uCred: UserCredential = await createUserWithEmailAndPassword(auth, v.email, v.password);
            if(uCred && uCred.user) {
                // Upload profile
                const x = await uploadAsync((v as any).profile, fbStoragePaths.profiles, uCred.user, () => null);
                // returns a response of a string if the image is saved in storage
                if(x.success) {
                    // get reference to the storage image and save it for retrieval in future
                    // await updateProfile(uCred.user, {photoURL: x.data}); 
                    await updateDocAsync(fsPaths.users, uCred.user.uid, {profileImageUrl: x.data});
                    // Signout User
                    await signOut(auth);
                    const res = await playerIUserRegAsync(v, uCred.user);
                    return await postPlayerAndReturnIUserAsync(res, tournamentId, teamId);
                } else {
                    // Signout User
                    await signOut(auth);
                    // Continue  and have the user update profile later
                    const res = await playerIUserRegAsync(v, uCred.user);
                    return await postPlayerAndReturnIUserAsync(res, tournamentId, teamId);
                }
            } else {
                // User creation failed
                const res: IResponse<IUser> =  {success: false, exists: false, message: 'System failure. Please try again!'};
                return res;
            }
        } catch (error) {
            const res: IResponse<IUser> =  {success: false, exists: false, message: (error as any).message};
            return res;
        }
    }


  return { playerRegistrationAsync }
};