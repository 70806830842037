import React from 'react';
// import './AwError.scss'


interface Props {
   error: string;
}
export const AwError: React.FC<Props> = ({error}) => {
 return (
    <>{error && <p style={{color: 'red'}}>{error}</p>}</>
 );
}