import { fieldTypes } from "../field";

export const userFields = [
    {name: 'firstName', required: true},
    {name: 'lastName', required: true},
    {name: 'preferredName'},
    {name: 'dob', label: 'Date of Birth', required: true, type: fieldTypes.date},
    {name: 'mobile', required: true}
    // {name: 'email', required: true},
    // {name: 'profileImageUrl'}
]

export const emergencyFields = [
    {name: 'emergencyContactName', required: true},
    {name: 'emergencyContactMobile', required: true, type: fieldTypes.phone}
]

export const userAccountFields = [
    {name: 'email', required: true},
    {name: 'password', required: true, type: fieldTypes.password},
    {name: 'confirmPassword', required: true, type: fieldTypes.password}
]

