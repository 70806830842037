import { NavLink } from 'react-router-dom';
import './ALink.scss'

interface Props {
   url: string;
   state?: any;
   style?: any;
   className?: string;
   children: any;
}
export const ALink: React.FC<Props> = ({url, ...props}) => {
 return (
    <NavLink {...props} to={url} />
 );
}
