import { useRef, useState } from 'react';
import { IField, ModalForm } from '../../forms';
import { IObject } from '../../InterfacesOrTypes';
import { checkFormRequiredValidity } from '../../utils';
import { Pbutton } from '../btns';
import { FormModal } from './FormModal';
// import './ModalWithForm.scss'

interface IModalWithForm {
    title: string;
    btnLabel: string;
    fields: IField[];
    btnCompName?: any;
    onSubmit: (v: IObject, close: ()=> void) => void;
    children?: any;
}
export const ModalWithForm: React.FC<IModalWithForm> = ({title, btnLabel = "Submit", btnCompName: BtnCom = Pbutton, fields, onSubmit, children, ...props}) => {
    const ref = useRef();
    const openModal = () => {
        (ref.current as any).open();
    }
    

    
    const [error, setError] = useState('');
    const [values, setValues] = useState<IObject>({});

    const handleSubmit = async () => {
        setError('');
        const results = checkFormRequiredValidity(values, fields);
        if(results.length > 0) return setError(results[0]);
        onSubmit(values, () => { setValues({}); return (ref.current as any).close()});
    }
    

    const handler = (v: any) => {
        setValues(x => ({...x, [v.name]: v.value}));
    }
    
    return (
        <div className='modal-with-form'>
            <BtnCom clicked={openModal} label={btnLabel} />
            <FormModal ref={ref} title={title} onSubmit={handleSubmit} {...props} >
                {error && <p style={{color: 'red'}}>{error}</p>}
                {children}
                <ModalForm formName='address_modal' fields={fields} handleValueChange={handler} />
            </FormModal>
        </div>
    )
}
