import { where } from 'firebase/firestore';
import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSettings, useAuth } from '../context';
import { fsPaths, IPlayer, MobileHeader } from '../shared';
import { useSingleData } from '../shared/cus-hook/useLiveData';
// import { PageLinks } from './components';
import './Layout.scss';
// .layout {
//     padding-top: 6rem;
//     width: 100%;
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 0;
// }


// const useLayoutSettings = () => {
//     const [header, setHeader] = useState<string>('');
    
//     const {tournament} = useAppSettings();
//     const {user} = useAuth();
       
//     const query = useMemo(() => {
//         // Uisng the live update because 
//         // when a player calls or text that they have made the payment they should see the changes
//         // as soon as I do
//         if(!tournament) return [];

//         return [
//             where('uid', '==', `${user.uid}`),
//             where('tournamentId', '==', `${tournament?.id}`)
//         ];
//     }, [tournament])


//     const {data: player} = useSingleData<IPlayer>(fsPaths.players, query);

//     const changeHeader = (h: string) => {
//         setHeader(h);
//     }

//     return {header, player: {name: 'good'} as any, changeHeader};
// }


export const Layout = () => {
    return (
       <div className='layout'>
            <MobileHeader />
            <Outlet />
       </div> 
    );
}


// export const Layout = () => {
//     const {notification: {location: loc}} = useAppSettings();
//     return (
//         <div className='layout'>
//             <MobileHeader />
//             <CoverContainer {...{id: 'layout'}} className='layout'>
//                 {loc === notificationLocations.main && <PgNotification />}
//                 <Outlet />
//             </CoverContainer>
//         </div>
//     )
// }

