import { Outlet } from "react-router-dom";
import { urls } from "../../menus";
import { Navigate, useLocation } from "../RoutingModules";

export const RequireData = () => {
    const {state} = useLocation();
    const allow = (!!state && (state as {data: any}).data)
    return (
        allow ? 
        <Outlet />
        : <Navigate to={urls.redirection} />
    )
}