import { useOutletContext } from 'react-router-dom';
import { notificationTypes, useAuth } from '../../../context';
import { emergencyFields, Form, FormHeader, userFields, useToastNotify } from '../../../shared';
// import './UserUpdateForm.scss'

export const UserUpdateForm = () => {
    const {user, updateFirestoreUserAsync} = useAuth();
    const {handleToaster} = useToastNotify();
    const handleSubmit = async (values: any) => {
        const res = await updateFirestoreUserAsync({...values}, values);
        if(res.success) return handleToaster({msg: "Your info has been updated successfully!", type: notificationTypes.success});
        handleToaster({msg: "Update failed. Please try again later!", type: notificationTypes.error})
     }
    return (
        <div className='change-user-info'>
            <FormHeader title='Update Info' />
            <Form 
                settings={{btnLabel: 'Save Changes'}} 
                initObj={user} 
                fields={[{name: "email", required: true}, ...userFields]}
                onSubmit={handleSubmit} 
                formName={'Profile Edit'} />
        </div>
    );
}


export const UserEmergencyForm = () => {
    const {user, updateFirestoreUserAsync} = useAuth();
    const {handleToaster} = useToastNotify();
    const {onUpdate} = useOutletContext<any>();


    const handleSubmit = async (values: any) => {
        const res = await updateFirestoreUserAsync({...values}, values);
        if(res.success) {
            onUpdate(values);
            return handleToaster({msg: "Your info has been updated successfully!", type: notificationTypes.success});
        }
        handleToaster({msg: "Update failed. Please try again later!", type: notificationTypes.error})
     }
    return (
        <div className='change-user-emergency'>
            <FormHeader title='Emergency Contact Update' />
            <Form 
                settings={{btnLabel: 'Save Changes'}} 
                initObj={user} 
                fields={[...emergencyFields]}
                onSubmit={handleSubmit} 
                formName={'Emergency Contact'} />
        </div>
    );
}