import { FaFacebookF, FaTwitter, FaInstagram, FaEye, FaEyeSlash, FaPlus, FaFilter, FaQuestionCircle } from 'react-icons/fa';
import { ImRadioUnchecked, ImRadioChecked2 } from 'react-icons/im'
import { HiOutlineUpload } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import Google from "../logos/google.png";

export const FilterIcon = () => {
    return <span><FaFilter /></span>
}

export const QuestionCircleIcon = () => {
    return <span><FaQuestionCircle /></span>
}
export const UploadIcon = () => {
    return <span><HiOutlineUpload /></span>
}
export const PlusIcon = () => {
    return <span className='icon'><FaPlus /></span>
}

export const CloseIcon = () => {
    return <IoClose />
}

export const GoogleIcon = () => {
    return <img src={Google} style={{
        width: '30px'}} />
}
export const FbIcon = () => {
    return <FaFacebookF />
}
export const TwitterIcon = () => {
    return <FaTwitter />
}
export const InstagramIcon = () => {
    return <FaInstagram />
}

export const RadioCheckedIcon = () => {
    return <ImRadioChecked2 />
}

export const RadioUnCheckedIcon = () => {
    return <ImRadioUnchecked />
}

export const ShowIcon = () => {
    return <FaEye />
}

export const HideIcon = () => {
    return <FaEyeSlash />
}