import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useAppSettings, useAuth } from '../../../context';
import { ALink, getTimeOfDay, IPlayer, ShadowCard, urls, useIncompleteAccount } from '../../../shared';
import './Dashboard.scss';
// import { usePlayerNotSignedUp } from './usePlayerNotSignedUp';

export const Dashboard = () => {
    // const { player } = useOutletContext<{player: IPlayer;}>();
    const context = useOutletContext<any>();
    const {tournament} = useAppSettings();
    const { player: playerData} = useAuth();
    const { routes, Comp } = useIncompleteAccount();

    const player: IPlayer = useMemo(() => {
        if(!context) return context;
        const {player: p} = context;
        return p;
    }, [context])

    return (
        <div className='dashboard'>
            <Greeting />

            {!player && !playerData && (
                <ShadowCard>
                   <p>Do you want to  <ALink url={urls.playertotournament}>join the current tournament</ALink>? </p>
                </ShadowCard>
            )}

            {routes.length > 0 &&
                <ShadowCard>
                    <h2>Incomplete Account</h2>
                    <Comp routes={routes} />
                </ShadowCard>
            }

            {player && <>
                {player.isPaid || player.isWaived ? <></> : player.markedAsPaid ? (
                    <ShadowCard>
                        <p>We will start working on your payment shortly!</p>
                    </ShadowCard>
                ) : (
                    <ShadowCard>
                        <h2>Payment Required</h2>
                        {tournament && <ALink url={urls.tournamentpayment}>Pay your player fee of ${tournament.playerFee}</ALink>}
                    </ShadowCard>
                )}
            </>}
            
        </div>
    );
}


const Greeting = () => {
    const { user } = useAuth();
    return (
        <h2 style={{textAlign: 'right'}}>{getTimeOfDay(user.preferredName || user.firstName)}</h2>
    )
}
