import { Outlet } from 'react-router-dom';
import { notificationLocations, useAppSettings } from './context';
import './Entry.scss';
import { BackdropBouncyLoader, CoverContainer, MobileHeader, Routes, setExtensionMethods } from './shared';
import { PgNotification } from './shared/components/notifications/page/PgNotification';


export const Entry = () => {
    setExtensionMethods();
    const { loading } = useAppSettings();
    // <EntryWithLoading><Routes /></EntryWithLoading>
    return (
        <div className='entry'style={{ WebkitTapHighlightColor: 'transparent' }}>
            {loading && <BackdropBouncyLoader />}
            <Routes />
        </div>
    );
}

const EntryWithLoading = ({...props}) => {
    const { loading } = useAppSettings();
    return (
        <div className="entry" style={{ WebkitTapHighlightColor: 'transparent' }}>
            {loading && <BackdropBouncyLoader />}
            {props.children}
        </div>
    )
}




