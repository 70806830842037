import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { contacts } from '../../../../contactInfo';
import { notificationTypes, useAppSettings } from '../../../../context';
import { ITeam, ITournament, Pbutton, urls, useAlert, usePlayer, useToastNotify } from '../../../../shared';
import { Dropdown } from '../../../../shared/forms/field/types';
import './PlayerToTournament.scss';

export const PlayerToTournament = () => {
    const navigate = useNavigate();
    const {tournament, registeredTeams: teams} = useAppSettings();
    const [selectedTeam, setSelectedTeam] = useState<ITeam | any>(null);
    const { joinTournamentAsync } = usePlayer();
    const { alertUser } = useAlert();
    const { handleToaster } = useToastNotify();

    const teamSelected = (v: any) => {
        setSelectedTeam(v);
    }


    const joinTeam = async () => {
        const res = await joinTournamentAsync((tournament as ITournament).id as string, (selectedTeam as ITeam).id as string);
        if(res.success) {
            handleToaster({msg: 'You have joined the tournament successfully. You only need to pay your fee.', type: notificationTypes.success});
            navigate(urls.player.base, {state: {data: res.data }});
        } else {
            alertUser(res.message);
        }
    }

    return (
        <div className='player-to-tournament'>
            
            <h2>Select your team</h2>
            <Dropdown 
                vKey={'id'} 
                dKey={'name'} 
                list={teams} 
                onSelected={teamSelected} 
                shouldPreselect={false} 
                shouldSelectValue={false} />
            {selectedTeam && <Pbutton label={`Join ${selectedTeam.name}`} clicked={joinTeam} />}
            <p>Cannot find your team? Have your coach/team lead call <strong>{(contacts.main.mobile as any).phone()}</strong> to register the team.</p>
        </div>
    );
}
