import { Outlet } from "react-router-dom";
import { useAuth } from "../../../context";
import { urls } from "../../menus";
import { Navigate, useLocation } from "../RoutingModules";

export const RequireAuth = ({...props}) => {
    const {user} = useAuth();
    const location = useLocation();

    // return (
    //     auth.user ? <Outlet /> : 
    //     <Navigate to={urls.signin} state={{ from: location }} replace />
    // )


    // Basically check the roles of the user to see if they are allowed to view the page they request
    // If they are not in the role, then check to see if they are signed in 
    // If they  are signed in then show the unauthorized page else navigate to signin
   

    return (
        user.isAuthenticated ? 
        (props.allowedRoles as string[]).find(role => user.roles.includes(role))
        ? <Outlet /> 
        : <Navigate to={urls.unauthorized} state={{ from: location }} replace />
        : <Navigate to={urls.signin} state={{ from: location }} replace />
    )
}