import { collection, onSnapshot, query, Unsubscribe, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../../firebase-config";
import { fsPaths, IAddress, ITeam, ITournament, ITournamentTeam, useFirestore } from "../shared";


interface IObject {
    [x: string]: any
}
interface IMobileMenuState {
    isMenuShowing: boolean;
    hideMenu: () => void;
    showMenu: () => void;
}
interface IMobile extends IMobileMenuState {}

export const notificationTypes = {
    error: 'error',
    success: 'success',
    info: 'info',
    warning: 'warning'
}
export const notificationLocations = {
    main: 'main',
    profile: 'profile'
}
export interface INotification {
    isNotify: boolean;
    type: string;
    msg: string;
    timeout: number;
    canClose: boolean;
    location: string;
    link: any;
}
export interface INullableNotification {
    isNotify?: boolean;
    type?: string;
    msg?: string;
    timeout?: number;
    canClose?: boolean;
    location?: string;
    link?: any;
}

interface IPage {
    scroll: {y: number; x: number; setScroll: (v: IObject) => void;}
}

interface IAppSettings {
    // mobile: IMobile;
    // page: IPage;
    tournament?: ITournament;
    registeredTeams: ITeam[];
    remainingTeams: ITeam[];
    notification: INotification;
    handleNotification: (v: INullableNotification) => void;
    loading: boolean;
    handleLoading:  (v: boolean) => void;
}



const AppContext = createContext<IAppSettings>({} as IAppSettings);

export const AppProvider = ({...props}) => {
    const {children} = props;

    // const mobileMenUpdate = (v: boolean) => {
    //     updateSettings(state => ({...state, mobile: {...state.mobile, isMenuShowing: v}}));
    // }
    // const setScroll = (x: IObject) => {
    //     updateSettings(state => ({...state, page: {...state.page, scroll: {...state.page.scroll, ...x}}}));
    // }
    // const showMobileMenu = () => mobileMenUpdate(true);
    // const hideMobileMenu = () => mobileMenUpdate(false);

    const [notification, setNotification] = useState<INotification>(
        {isNotify: false, type: notificationTypes.info, msg: '', canClose: true, timeout: 5000, location: notificationLocations.main, link: ''}
    );
    const [loading, setLoading] = useState(true);
    const [registeredTeams, setRegisteredTeams] = useState<ITeam[]>([]);
    const [remainingTeams, setRemainingTeams] = useState<ITeam[]>([]);
    const [tournament, setTournament] = useState<ITournament>();
    const {readAsync, getByQueryAsync, getDocsFromSnapshot, getCurrentTournamentAsync} = useFirestore();
    
    const handleNotification = (v: INullableNotification) => {
        setNotification(state => ({...state, location: notificationLocations.main, link: null, isNotify: true, ...v}));
    }
    
    const handleLoading = (v: boolean) => {
        setLoading(v);
    }

    useEffect(() => {
        let ttUnsubscribe: Unsubscribe;

        (async function() {
            setLoading(true);
            // I need current tournament
            let curTourna:ITournament;
            // const tRef = collection(firestore, fsPaths.tournaments);
            // const q = query(tRef, where('done', '==', false));// , where('startDate', '>=', new Date())
            // const res = await getByQueryAsync<ITournament>(q, false);
            const res = await getCurrentTournamentAsync();

            if(res.success) {
                curTourna = res.data || {} as ITournament;
                const aRes = await readAsync<IAddress>(fsPaths.addresses, res.data?.defaultParkId || "");
                curTourna.defaultPark = aRes.data as IAddress;
                // I also need current subscribed teams
                if(curTourna) {
                    const ttRef = collection(firestore, fsPaths.tournamentTeams);
                    const colRef = collection(firestore, fsPaths.teams);
                    const q = query(ttRef, where('tournamentId', '==', (curTourna as any).id));
                    ttUnsubscribe = onSnapshot(q, async (ttSnapshot) => {
                        const tournamentTeams: ITournamentTeam[] = getDocsFromSnapshot(ttSnapshot);
                        const teamIds = tournamentTeams.map(tt => tt.teamId);
                        if(teamIds && teamIds.length > 0) {
                            const q = query(colRef, where('__name__', 'in', teamIds));
                            const tRes = await getByQueryAsync<ITeam>(q);

                            if(tRes.success) {
                                const te: ITeam[] = (tRes.data as any);
                                const rmTeams: ITeam[] = [];
                                te.forEach(t => {
                                    const xTT = tournamentTeams.find(tt => tt.teamId == t.id);
                                    t.passcode = xTT?.passcode;
                                    t.eliminated = xTT?.eliminated;
                                    if(!xTT?.eliminated) rmTeams.push(t);
                                })
                                setRegisteredTeams(te);
                                setRemainingTeams(rmTeams);
                            }
                        } else {
                            setRegisteredTeams([]);
                            setRemainingTeams([]);
                        }
                    });


                    // set tournament
                    setTournament(curTourna);
                }

            } 
            setLoading(false);
        }())
        
        return () => {
            if(ttUnsubscribe) ttUnsubscribe();
        }
    },[])
     
    
    return (
        <AppContext.Provider value={{ 
            tournament, 
            registeredTeams, 
            remainingTeams,
            notification, 
            handleNotification, 
            loading, 
            handleLoading }}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppSettings = () => {
    return useContext(AppContext)
}


