import {useEffect, useState } from 'react';
import { contacts } from '../contactInfo';
import { notificationTypes, useAppSettings } from '../context';
import { ALink, ITeam, ITournament, PbuttonLink, urls, useToastNotify } from '../shared';
import { Dropdown } from '../shared/forms/field/types';
import './Landing.scss';

export const Landing = () => {
    // const [teams, setTeams] = useState<ITeam[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<ITeam | any>(null);
    const {tournament, registeredTeams} = useAppSettings();

    const teamSelected = (v: any) => {
        setSelectedTeam(v);
    }
    

    useEffect(() => {
        (async function() {
            // const res = await readAllAsync<ITeam>(fsPaths.teams);
            // if(res.success) setTeams(res.data || []);
            // allPlayAllSetup()
        }())
    },[])


    return (
        <div className='landing-pg'> 
            <div className='header'>
                <h1>Welcome to Leapstep Tournaments</h1>
            </div>
            <div className='body'>
                <div>
                    {tournament ? (
                        <>
                            <h2>To signup your team, call {(contacts.main.mobile as any).phone()}</h2>
                            <p>After signing up, your players can go ahead and join the team.</p>
                        </>
                    ) : <h3>Upcoming Fun Games Will Be Posted Here</h3>}
                </div>
               
               <div>
                    {registeredTeams.length > 0 && 
                    (
                        <>
                            <p>Select your team in the dropdown below and start registration. </p>

                            <div>
                                <h2>Player registration begins here</h2>
                                <Dropdown vKey={'id'} dKey={'name'} list={registeredTeams} onSelected={teamSelected} shouldPreselect={false} shouldSelectValue={false} />
                                {selectedTeam && <PbuttonLink url={urls.playerregistration.base} state={{data: {team: (selectedTeam as ITeam), tournament: (tournament as ITournament)}}}>Join {selectedTeam.name}</PbuttonLink>}
                            </div>

                            <p>Cannot find your team? Have your coach/team lead register the team.</p>
                        </>
                    )}
               </div>

                {/* <div>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Debitis enim, ullam quos distinctio eum illum quidem mollitia cumque tempore perferendis consectetur consequatur pariatur soluta est blanditiis, quam quis facilis quae.
                </div> */}
            </div>
            <div className='sign-in-div' >
                Already a member? <ALink url={urls.signin}>Sign In</ALink>
            </div>
        </div>
    );
}


