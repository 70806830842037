import { notificationTypes } from '../../../../context';
import { insertHTMLPositions } from '../../../utils';
import './toaster.scss';
const toastRPosition = {
    topRight: 'topRight'
}
export const useToastNotify = (position = toastRPosition.topRight) => {
    const content = document.getElementById('content-root');
    let container = (content as any).querySelector('#toast-notify');

    const appendToast = (msg: string, type: string) =>{
        container?.insertAdjacentHTML(
            insertHTMLPositions.lastChildOfAnEle, 
            `
               <p class="toast ${type}">${msg}</p>
            `
        )
        
        const toast = container.lastElementChild;
        toast.addEventListener('animationend', () => {
            // check for siblings. 
            // if no siblings then delete the container
            if(!toast.nextElementSibling) {
                container.remove();
                container = null;
            } else {
                toast.remove();
            };
        });
    }


    const initializeToast = () => {
        content?.insertAdjacentHTML(
            insertHTMLPositions.firstChildOfAnEle, 
            `
                <div 
                    id="toast-notify" 
                    style="position: fixed; top: 80px; right: 2rem; z-index: 10000;"
                    class="${position}"
                    >
                </div>
            `
        )

        container = content?.firstElementChild;
    }

    const handleToaster = ({msg, type = notificationTypes.success}: {msg: string; type: string;}) => {
        if(!container) initializeToast();
        appendToast(msg, type);
    }


    return {handleToaster};
}
