import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pbutton, urls } from "../../shared";
import styles from "./otherpages.module.scss";



export const Redirection = () => {
    const [countDown, setCountDown] = useState(5);
    const navigate = useNavigate();
    const goBack = () => navigate(-2);
    useEffect(() => {
        const interval = setInterval(() => {
            if(countDown > 0) setCountDown(state => state - 1);
            if(countDown < 1) return navigate(urls.home);
        }, 1000)
        return () => {if(interval) clearInterval(interval)}
    },[countDown])

    return (
        // <div style={styles}>
        <div className={styles.FlexiblePage}>
            <h2>Not Allowed!</h2>
            <p>You will be redirected to the home page in {countDown}!</p>
            <Pbutton clicked={goBack} label={'Go Back'} />
        </div>
    )
}
