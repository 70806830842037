import { where } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useAppSettings, useAuth } from "../../context";
import { fsPaths, IPlayer, useLocation, useSingleData } from "../../shared";
import { IPageLink } from "./MainLayout";

export const useLayoutSettings = (pageLinks: IPageLink[]) => {
    const [header, setHeader] = useState<string>('');
    const location = useLocation();
    
    const {tournament} = useAppSettings();
    const {user} = useAuth();
       
    const query = useMemo(() => {
        // Uisng the live update because 
        // when a player calls or text that they have made the payment they should see the changes
        // as soon as I do
        if(!tournament) return [];

        return [
            where('uid', '==', `${user.uid}`),
            where('tournamentId', '==', `${tournament?.id}`)
        ];
    }, [tournament])


    const {data: player} = useSingleData<IPlayer>(fsPaths.players, query);

    const changeHeader = (h: string) => {
        setHeader(h);
    }

    useEffect(() => {
        const filtered = getFiltered(pageLinks, location.pathname);
        const header = filtered[0].name;
        changeHeader(header);
    }, [location])
    
    return {header, player: player, changeHeader};
}


const getFiltered = (links: IPageLink[], path: string) => {
    let list = links.filter(a => a.route === path);
    const exists = list[0];
    if(exists) return list;

    return [links[0]];
    // if(urls.player.base === path) return [links[0]];

            
    // return links
    //         .filter((a, idx) => 
    //             {
    //                 console.log(a.route, path);
    //                 if(idx === 0) return a.name.toUpperCase();

    //                 return path === urls.player.base 
    //                     ? 'Dashboard' 
    //                     : a.route === path
    //         });
}