import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUser, IUserAccount, playerRegKeys, useAuth, userRoles, useWebStorage } from '../../../context';
import { ALink, AwError, Form, FormHeader, GoogleButton, loginFields, urls } from '../../../shared';
// import './SignIn.scss'
import styles from "../auth.module.scss";



 
export const SignIn = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const from = (location.state as any)?.from?.pathname || urls.home;
    const {loginAsync, loginWithGoogleAsync} = useAuth();
    const [error, setError] = useState<any>('');
    const { getItem, removeItem } = useWebStorage();

    const isFirstTime: boolean = useMemo(() => {
        return !!getItem(playerRegKeys.passwordSignin);
    }, [])

    const handleNavigate = (user: IUser) => {
        if(user) {
            const roles = user.roles;
            const rootUrl = from !== urls.home ? 
                            from 
                            : user.isAdmin ? 
                            urls.admin.base 
                            : (roles.includes(userRoles.ref) || roles.includes(userRoles.ar)) ? 
                            urls.referee.dashboard 
                            : (roles.includes(userRoles.player)) ? 
                            urls.player.dashboard : 
                            urls.pagenotfound;

            navigate(rootUrl, { replace: true });
        }
    }

    const handleSubmit = async (values: IUserAccount) => {
        setError('');
       if(loginAsync) {
           const res = await loginAsync(values, isFirstTime);
           if(res.success) {
            if(isFirstTime) removeItem(playerRegKeys.passwordSignin);
            return handleNavigate(res.data as IUser);
        };
           setError(res.message);
       }
    }


    const googleLogin = async () => {
        setError('');
        if(loginWithGoogleAsync) {
            const res = await loginWithGoogleAsync();
            if(!res.success) {
                setError(res.message);
            } else {
                handleNavigate(res.data as IUser);
            }
        }
    }


    return (
        <div className={styles.AuthPageContainer}>
            <div className={styles.AuthFormContainer}>
                {isFirstTime ? <h2 style={{textAlign: 'center'}}>Please Sign in with your email and password!</h2> : <FormHeader title='Sign In' />}
                <AwError error={error} />
                <Form fields={loginFields} formName={'sign in'} onSubmit={handleSubmit} /> 
                <ALink url={urls.forgotpassword}>Forgot Password?</ALink>
                {!isFirstTime && <div style={{width: '100%', marginTop: '15px'}}>
                    <GoogleButton label='Sign In With Google' clicked={googleLogin} />
                </div>}
            </div>
        </div>
    );
}
