import { useEffect, useState } from "react";
import { useAppSettings } from "../../context";
import { IGame } from "../InterfacesOrTypes";
import { convertTimestampToDateTime } from "../utils";

export const useGameTimer = (game: IGame) => {
    const {tournament} = useAppSettings();
    const [timer, setTime] = useState({des: '', time: '00:00:00'});
    const [stop, setStop] = useState(false);

    function formatTime(milliseconds: number) {
        const seconds = Math.floor(milliseconds/1000);
        const s = Math.floor(seconds%60);
        const m = Math.floor((seconds%(60 * 60)) / 60);

        const h = Math.floor((seconds%(60 * 60 * 24))/(60*60));
        let formattedTime = h.toString().padStart(2, '0');
        formattedTime += ":";
        formattedTime += m.toString().padStart(2, '0');
        formattedTime += ":";
        formattedTime += s.toString().padStart(2, '0');
        return formattedTime;
    }
    function getMillisecondsDuration(startTime: number) {
        const now = new Date().getTime();
        return (now - startTime);
    }
    

    function getGameTimer(startTime: any) {
        const date = convertTimestampToDateTime(startTime);
        const startTimeInMilli = date.getTime();
        const gameTime = tournament?.gameTime as number;
        // Break time for countdown
        let breakTime: number = 0;
        if(gameTime > 80) {
            breakTime = 10; 
        } else if (gameTime <= 40) {
            breakTime = 2;
        } else {
            breakTime = 5;
        }
        // This is the half time for gametime to pause
        const halfTime = gameTime / 2;
        
       
        // breakTime = 75;// in minutes
        return function updateTimer() {
            let displayTimeInMilliseconds = getMillisecondsDuration(startTimeInMilli);
             // minutes played 
            const minutesPlayed = (getMillisecondsDuration(startTimeInMilli) / 60000);
            let des = 'First Half';
            
            if(minutesPlayed > (halfTime + breakTime) && minutesPlayed <= (gameTime + breakTime)) {
                // we are in second half
                des = 'Second Half';
                displayTimeInMilliseconds = (displayTimeInMilliseconds - (breakTime * 60000))
            } else if(minutesPlayed > (gameTime + breakTime)  || minutesPlayed > (gameTime + breakTime + 5) ) {// 5 minutes just for extra time
                
                setStop(true);
                return {des: 'Game Over', time: `${gameTime}:00`}
            } else if(minutesPlayed >= halfTime && halfTime < (halfTime + breakTime)) {
                
                // halftime countdown before second half
                des = 'Half Time';
                const now =  new Date().getTime();
                const secondHalfStartsInMilli = new Date((startTimeInMilli + (halfTime*1000*60) + (breakTime*1000*60))).getTime();
               
                displayTimeInMilliseconds = (secondHalfStartsInMilli - now);
            } 
            
            return {des, time: formatTime(displayTimeInMilliseconds)};
        }
    }

    useEffect(() => {
        let interval: any;
        if(!stop && !interval && game && (!game.isPlayed && game.isStarted) && tournament) {
            const startTime = getGameTimer(game.startTime);
            // const startTime = getGameTimer(new Date("Sep 7, 2022 20:40:00"));
            interval = setInterval(() => {
                setTime(startTime());
            }, 1000)
        }
        return () => {
            if(interval) clearInterval(interval);
        }
    },[game, tournament, stop])

    return {
        timer
    }
}