import { where } from "firebase/firestore";
import { useMemo } from "react";
import { useAppSettings, useAuth } from "../../context";
import { MainLayout } from "../../layout";
import { fsPaths, IPlayer, urls} from "../../shared"
import { useSingleData } from "../../shared/cus-hook/useLiveData";


export const Player = () => {
    const asidelinks = [
        {route: urls.player.dashboard, name: 'Dashboard'},
        {route: urls.player.teams, name: 'Teams'},
        {route: urls.player.games, name: 'Games'},
        {route: urls.player.scoreboard, name: 'ScoreBoard'},
        {route: urls.player.standings, name: 'Standings'},
        {route: urls.player.leaderboard, name: 'LeaderBoard'},
        {route: urls.player.rules, name: 'Rules'}
    ]
    // const location = useLocation();
    // const [header, setHeader] = useState<string>(asidelinks.filter(a => location.pathname === urls.player.base ? 'Dashboard' : a.route === location.pathname)[0].name);
    // // const [player, setPlayer] = useState<IPlayer>(null as any);
    const {tournament} = useAppSettings();
    const {user} = useAuth();
    // const { readPlayerAsync } = usePlayer();
       
    const query = useMemo(() => {
        // Uisng the live update because 
        // when a player calls or text that they have made the payment they should see the changes
        // as soon as I do
        if(!tournament) return [];

        return [
            where('uid', '==', `${user.uid}`),
            where('tournamentId', '==', `${tournament?.id}`)
        ];
    }, [tournament])

    const {data: player} = useSingleData<IPlayer>(fsPaths.players, query);
    
    const paidLinks = useMemo(() => {
        if(player && (player.isPaid || player.isWaived)) return [];
        return asidelinks.filter(a => !['dashboard', 'teams', 'games','rules'].includes(a.name.toLowerCase())).map(lk => lk.name);
    }, [player])
    
    return (
        <MainLayout className="player" player={player} links={asidelinks} excLinks={paidLinks} />
    )
}
