import { forwardRef, useEffect, useState } from 'react';
import ReactDOM  from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context';
import { Arrow, Close } from '../../components';
import { useModalFunctions } from '../../components/modals/useModalFunctions';
import { IMenu, menuList, urls } from '../menu-list';


interface IMenuSetting {
    menus:IMenu[]; 
    show: Boolean;
}

export const MobileMenuModal = forwardRef<any, any>((_, ref) => {
    const {display, close } = useModalFunctions({ref, settings: {}});
    
    const navigate = useNavigate();
    const {user, logoutAsync} = useAuth();

    const [mainMenus, setMainMenu] = useState<IMenu[]>(menuList);

    const [dropdown, setDrop] = useState<IMenuSetting>({menus: [], show: false});

    const setDropdownMenus = (menus: IMenu[]) => {
        setDrop(state => ({...state, menus: menus, show: true}));
    }
    const showHideDropdownMenu = () => {
        setDrop(state => ({...state, show: !state.show}));
    }
    const resetMenus = () => {
        setDrop(state => ({...state, show: false}));
    }

    const handleClose = () => {
        resetMenus();
        close();
    }
    
    const handleNavigate = (url: string) => {
        navigate(url);
        handleClose();
    }

    const handleLogout = async () => {
        if(logoutAsync) await logoutAsync();
        handleClose();
        navigate(urls.home);
    }

    useEffect(() => {
        if(user.isAuthenticated) {
            const filterMenu = menuList.filter(mm => (mm.url as any).lowerCase() != urls.signin);
            setMainMenu(filterMenu);
            if(!user.isAdmin) {
                // if its not admin
                setMainMenu(menuList.filter(mm => !(['admin', 'signin', 'home'].includes((mm.name as any).normalize())) ) )
            }
        } else {
            setMainMenu(menuList.filter(mm => ([urls.home, urls.signin].includes(mm.url))))
        }
    },[user])
    

    if(!display) return null;

   
    return ReactDOM.createPortal(
        <div className="mobile-menu-wrapper show">
        <div className="backdrop" onClick={handleClose}></div>
        <div className="content">
        <div className="header">
            <div className="left-btns">
                {dropdown.show && (
                    <Arrow dir="left" clicked={() => showHideDropdownMenu()}>
                        <div>Back</div>
                    </Arrow>
                )}
            </div>
            <Close clicked={handleClose} />
        </div>
        <div className="menus" style={{overflowY: 'scroll'}}>
            {!dropdown.show && <MenuList 
                                    menus={mainMenus} 
                                    setDropdown={setDropdownMenus} 
                                    navigate={handleNavigate}/>}
            {dropdown.show && <MenuList 
                                    menus={dropdown.menus} 
                                    setDropdown={setDropdownMenus}
                                    navigate={handleNavigate}/>}

            {user.isAuthenticated && <div className="logout">
                <span onClick={handleLogout} className='mobile-menu-link'>Log out</span>
            </div>}
        </div>

        
        </div>
    </div>,
    (document.getElementById('mobile-menu-root') as HTMLDivElement)
   )
})



const MenuList: React.FC<{menus: IMenu[]; navigate: (v:string) => void; setDropdown: (menus: IMenu[]) => void; }> = ({menus, navigate, setDropdown}) => {
    return (<>
        {menus.map((menu, i) => {
            return (
                <MenuItem menu={menu} key={i} navigate={navigate}  setDropdown={setDropdown} />
            )
        })}
    </>)
}

const MenuItem: React.FC<{menu: IMenu; navigate: (v:string) => void; setDropdown: (menus: IMenu[]) => void; }> = ({menu, navigate, setDropdown}) => {
    const {rootUrl} = useAuth();
    const onlyDropdown = menu.children && !menu.url;

    return (
        <div className="menu">
            {onlyDropdown ? (
                <div className="mobile-menu-link" onClick={() => setDropdown(menu.children)}>
                    <span>{menu.name} </span>
                    <span><Arrow dir="right" /></span>
                </div>
            ) : (
                <div>
                    {!!menu.url ? <span className="mobile-menu-link" onClick={() => navigate(menu.name.normalize() === 'dashboard' ? rootUrl : menu.url)}>{menu.name} </span> : <span>{menu.name} </span> }
                    {menu.children && <span onClick={() => setDropdown(menu.children)}><Arrow dir="right" /></span>}
                </div>
            )}
        </div>
    )
}

