import { useState } from 'react';
import { useAuth } from '../../../context';
import { ALink, Form, FormHeader, IObject, loginFields, urls, useAlert } from '../../../shared';
import styles from "../auth.module.scss";

export const ForgotPassword = () => {
    const [fields] = useState([loginFields[0]]);
    const {sendForgotPasswordEmailAsync} = useAuth();
    const {alertUser} = useAlert();

    const handleSubmit = async (vals: IObject) => {
        console.log(vals);
        if(sendForgotPasswordEmailAsync) {
            const res = await sendForgotPasswordEmailAsync(vals['email']);
            if(res.success) {
                // password reset email sent successfully
            }
            alertUser("Thanks! If there's an account associated with this email, we'll send the password reset instructions immediately.");
        }
    }

    return (
        <div className={styles.AuthPageContainer}>
            <div className={styles.AuthFormContainer}>
                <FormHeader title='Forgot Password'/>
                <Form fields={fields} formName={'forgot password'} onSubmit={handleSubmit} settings={{btnLabel: "Send Reset Email"}} />
                <ALink url={urls.signin} >Go Back</ALink>
            </div>
        </div>
    );
}