import { onAuthStateChanged, signOut, User } from 'firebase/auth';
import { collection, query, where } from 'firebase/firestore';
import { useState, useEffect, useRef } from 'react';
import { auth, firestore } from '../../firebase-config';
import { castReturnValue, fsPaths, IObject, IPlayer, IResponse, urls, useAlert, useFirestore } from '../shared';
import { IUser, userRoles } from './AuthContext';

export const useInitialAuthState = () => {
  const fbUserRef = useRef<User>();
  let initUser = useRef<any>(null);
  const [authLoading, setLoading] = useState<boolean>(true);
  const [manualAuth, setManualAuth] = useState<boolean>(false);
  const [rootUrl, setRootUrl] = useState<string>(urls.home);
  const [user, setUser] = useState<IUser>({isAuthenticated: false} as IUser);
  const [player, setPlayer] = useState<IPlayer | null>(null);
  const { readAsync, getByQueryAsync, getCurrentTournamentAsync } = useFirestore();

  const {alertUser} = useAlert();
//   useEffect(() => {
    
//     return () => {
//     };
//   });

// new Promise((resolve) => {
//     const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
//         if(currentUser) {
//             console.log(currentUser);
//             // setUser(currentUser);
//             await ru(currentUser);
//             resolve(currentUser);
//         }
//         resolve(null);
//         unsubcribed();
//     })
// }

// let initAuthPromise = useMemo(() => {
//     console.log('getting called')
//     return new Promise((resolve) => {
//         const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
//             if(currentUser) {
//                 await readUser(currentUser);
//                 initUser.current = (currentUser as any);
//                 resolve(currentUser);
//             }

//             unsubcribed();
//             resolve(null);
//         })
//     })
// }, [])



    useEffect(() => {
        const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
            const cu = fbUserRef.current;
            const exist = Object.keys(cu || {}).length > 1;
            if(!manualAuth) {
                if(!!currentUser ) {
                    fbUserRef.current = currentUser;
                    if(!user.isAuthenticated) {
                        if(!authLoading) setLoading(true);
                        await readUser(currentUser);
                    }
                }
                setManualAuth(true);
            }
            if(authLoading) {
                setLoading(false);
            }
        })
    
        return () => {
            unsubcribed();
        }
    }, [])
    



const handleSetLoggedInUser = (data: IObject, isLoggedIn: boolean = true) => {
    setUser(state => ({...state, isAuthenticated: isLoggedIn,  ...(data as any)}));
}

const readUser = async (u: User) => {
    const cu = await readLoggedInUser(u.uid, {
        profileImageUrl: (u.photoURL as any),
        providerData: u.providerData
    });
    return cu;
}

const readLoggedInUser = async (uid: string, uiphotoObj: {}) => {
    const uRes = await readAsync<IUser>(fsPaths.users, uid);
    if(uRes.success && uRes.data) {
        const cu = uRes.data as IUser;
        const role = cu.roles[0];
        if(cu && role === userRoles.player) {
            // Set player value if there is a player and a tournament
            // setting this value so that I can use the initial loader
            const tRes = await getCurrentTournamentAsync();
            if(tRes.success && tRes.exists) {
                const q = query(collection(firestore, fsPaths.players),
                                    where('uid', '==', `${cu.uid}`),
                                    where('tournamentId', '==', `${(tRes.data as any).id}`));
                const pRes = await getByQueryAsync(q, false);
                if(pRes.success) {
                    if(pRes.exists && !player) setPlayer(pRes.data as IPlayer);
                }
            }

            setRootUrl(cu.isAdmin ? 
                urls.admin.base 
                : (cu.roles.includes(userRoles.ref) || cu.roles.includes(userRoles.ar)) ? 
                urls.referee.dashboard 
                : (cu.roles.includes(userRoles.player)) ? 
                urls.player.dashboard : 
                urls.pagenotfound);
            handleSetLoggedInUser(Object.assign((uRes.data as any), uiphotoObj));
        } else {
            await logoutAsync(role);
            const res = castReturnValue<IResponse<IUser | null>>(uRes, null);
            return res.data;
        }
    }
    
    return uRes.data;
}

const logoutAsync = async (msg?: string) => {
    try {
        setRootUrl(urls.home);
        await signOut(auth);
        handleSetLoggedInUser({isAuthenticated: false} as IUser, false);
        // msg is the role of the user i'm logging out
        // they are able to authenticate but through the wrong portal
        // this is only for players to authenticate and not admin or referees
        if(msg) alertUser(`You are trying to log in as a player. Please use the ${msg} portal.`);
    } catch (error) {
        alertUser((error as any).message);
    }
}

 

  return { initUser, user, player, fbUserRef, rootUrl, authLoading, setUser, readUser, handleSetLoggedInUser, logoutAsync }
};


// const promise = (ru: any) => new Promise((resolve) => {
//     const unsubcribed = onAuthStateChanged(auth, async (currentUser) => {
//         if(currentUser) {
//             console.log(currentUser);
//             // setUser(currentUser);
//             await ru(currentUser);
//             resolve(currentUser);
//         }
//         resolve(null);
//         unsubcribed();
//     })
// })