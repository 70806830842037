import './App.scss';
import { Entry } from './entry';
import { AppProvider, AuthProvider } from './entry/context';
import { AppRoutes, Route, Router, Routes } from './entry/shared';


export const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppProvider>
            <AppRoutes>
              <Route path='/*' element={<Entry />} />
            </AppRoutes>
        </AppProvider>
      </AuthProvider>
    </Router>
  );
};

