import { useNavigate } from "react-router-dom";
import { Pbutton } from "../../shared";
import styles from "./otherpages.module.scss";

export const UnAuthorized = () => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1)
    return (
        <div className={styles.FlexiblePage}>
            <h2>Permission Needed!</h2>
            <p>Sorry you do not have permission for the content you are requesting!</p>
            <Pbutton label="Go Back" clicked={goBack} />
        </div>
    )
}

