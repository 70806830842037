import { useRef } from 'react';
import './MobileMenu.scss';
import { MobileMenuModal } from './MobileMenuModal';


export const MobileMenu = () => {
    const funcRef = useRef();

    const openModal = () => {
        (funcRef.current as any).open();
    }
    
    return (
        <div>
            <div className="mobile-menu-btn-wrapper">
                <button onClick={openModal} className="btn-menu">Menu</button>
            </div>
            <MobileMenuModal ref={funcRef} />
        </div>
    )
}
