import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../../context';
import { urls } from '../../menus';

export const UnAuthOnlyRoute = () => {
    const {user} = useAuth();
    return (
        user.isAuthenticated ? 
        user.isAdmin ? 
        <Navigate to={urls.admin.base} replace /> 
        : <Navigate to={urls.player.dashboard} replace />
        : <Outlet />
    )
}