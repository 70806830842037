import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSettings } from "../../../../context";
import { IGame } from "../../../InterfacesOrTypes";
import { getDateMonthAndYrStringFromTimestamp, getDayTimeStringFromTimestamp } from "../../../utils";
import { ALink } from "../../link";
import './GameReadOnly.scss';

interface Props {
    game: IGame;
    className: string;
}
export const GameReadOnly: React.FC<Props> = ({game, className}) => {
    const location = useLocation();
    const {tournament} = useAppSettings();
    let gameLabel: string[] = [];
    gameLabel = game.label.split("vs");
    gameLabel.splice(1, 0, "vs");

    
    
    const mainPath = useMemo(() => {
        const seg =location.pathname.split("/");
        const mainPath = seg[seg.length - 2];
        return mainPath;
    }, [])
    
    return (
        <div style={{position: 'relative'}} className={`g-readonly ${className}`} >
            <label> 
                {gameLabel.map((l, idx) => {
                    return (
                        <span key={idx} className={idx === 1 ? 'vs' : 'team-name-label'}>
                            {l}
                        </span>
                    )
                })} 
            </label>
            <p className='park-wrapper'>
                {game.address ? game.address.name : tournament?.defaultPark ? tournament.defaultPark.name : tournament?.defaultParkId}
            </p>
            <p className='day-time'>{getDayTimeStringFromTimestamp(game.gameDate)}</p>

            <p className='mnth-yr'>{getDateMonthAndYrStringFromTimestamp(game.gameDate)}</p>


            {/* Show live game */}

            {game.signInStarted && !game.isPlayed && (
                <div className="live" style={{color: 'green', fontWeight: '700'}}>
                    <ALink url={`/${mainPath}/scoreboard`} style={{color: 'green', fontWeight: '700'}} >Live Game</ALink>
                </div>
            )}

            { game.isCancelled &&  <CancelledGame /> }
        </div>
    )
}


const CancelledGame = () => {
    return (<div className="cancelled">Cancelled</div>)
}
