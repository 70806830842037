import { useState, useEffect } from 'react';

export const playerRegKeys = {
    passwordSignin: 'password'
}
export const useWebStorage = () => {
  const [storage] = useState(localStorage);

    const setItem = (key: string, values: any) => {
        storage.setItem(key, JSON.stringify(values));
    }
    const getItem = (key: string) => {
        const v = storage.getItem(key);
        if(!v) return false;
        return JSON.parse(v);
    }

    const removeItem = (key: string) => {
      storage.removeItem(key);
    }

    const clearStorage = () => {
      storage.clear();
    }

  return { setItem, getItem, removeItem, clearStorage }
};