import { useNavigate } from 'react-router-dom';
import { UploadIcon } from '../../../assets';
import { useAuth } from '../../context';
import { ALink, urls } from '../../shared';
import styles from "./profile.module.scss";

export const ProfileImg = () => {
   const {user} = useAuth();
   const navigate = useNavigate();
   const handle = () => {
      navigate(urls.profile.base)
   }
   if(!user.isAuthenticated) {
      return <ALink className={styles.SignInLink} url={urls.signin}>Sign In</ALink>;
   };
   return (
      <div className={styles.ProfileImg} onClick={handle} >
         {/* {user.profileImageUrl  ? <img className='avatar' src={user.profileImageUrl} /> : (
            <div className="name-avatar">
               {user.firstName[0]}{user.lastName[0]}
            </div>
         )} */}
         <UserAvatar user={user} />
      </div>
   );
}

export const ProfilePageImg = () => {
   const {user} = useAuth();
   const navigate = useNavigate();
   const handle = () => {
      navigate(urls.profile.changeprofilepic);
   }
   if(!user.isAuthenticated) return null;
   return (
      <div className={styles.ProfileImg} onClick={handle} >
         <UserAvatar user={user} />
         <UploadIcon />
      </div>
   );
}


const UserAvatar = ({...props}) => {
   const {user} = props;
   return <>
      {user.profileImageUrl  ? <img className='avatar' src={user.profileImageUrl} /> : (
            <div className="name-avatar">
               {user.lastName ? <>{user.firstName[0]}{user.lastName[0]}</> : "JS"}
            </div>
         )}
   </>
}