import React, { useEffect, useState } from 'react';
import { ICurrentTarget } from '../../..';
import { RadioCheckedIcon, RadioUnCheckedIcon } from '../../../../../../assets';
import './RadioInput.scss'

interface Props {
    name: string;
    onChange: (v: ICurrentTarget) => void;
    label?: string;
    init?: boolean;
}
export const RadioInput: React.FC<Props> = ({name, onChange, label, init}) => {
    const [checked, setChecked] = useState(!!init)
    const handleChange = () => {
        const x = !checked;
        setChecked(x);
        onChange({currentTarget: {name: name, value: x}})
    }
    useEffect(() => {
        onChange({currentTarget: {name: name, value: checked}});
    },[])
    return (
        <div className="field radio-btn" onClick={handleChange}>
            { checked ? <RadioCheckedIcon /> : <RadioUnCheckedIcon /> }
            <label>{label}</label>
        </div>
    )
}