import { collection, onSnapshot, Query, query, QueryConstraint, Unsubscribe } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { firestore } from "../../../firebase-config";
import { useFirestore } from "./firestore-repos";

// export function useLiveData<T>(path: string, clauses: QueryConstraint[]): {data: T} {
//     const [data, setData] = useState<T>(null as any);
//     const {getDocsFromSnapshot} = useFirestore();

//     const getQueries = (path: string, ...qc: QueryConstraint[]) => {
//         return query(collection(firestore, path), ...qc);
//     }

//     useEffect(() => {
//         let unsubscribe: Unsubscribe;
//         //   const clauses: QueryConstraint = where('gameId', 'in', gameIds);
//         unsubscribe = onSnapshot(getQueries(path, ...clauses), (snapshots) => {
//             const sc = getDocsFromSnapshot(snapshots);
//             setData(sc as any);
//         })
    
//         return () => { if(unsubscribe) unsubscribe(); };
//     }, []);

//     return {data}
// }

export function useSingleData<T>(path: string, clauses: QueryConstraint[]): {data: T} {
    const [data, setData] = useState<T>(null as any);
    const {getDocsFromSnapshot} = useFirestore();

    const observeQ: Query = useMemo(() => {
        return query(collection(firestore, path), ...clauses);
    }, [clauses])

    useEffect(() => {
        let unsubscribe: Unsubscribe;

        // const q = query(collection(firestore, path), 
        // where('tournamentId', '==', "O0PuPQxP9pRcmkeGy0io"), 
        // where('uid', '==', 'D6Tba2z8sNcm6InXuUncXkvGk8I3'));
        if(clauses.length) {
            unsubscribe = onSnapshot(observeQ, (snapshots) => {
                const sc = getDocsFromSnapshot(snapshots);
                if(sc[0]) setData(sc[0]);
            })
        }
        
        return () => { if(unsubscribe) unsubscribe(); };
    }, [observeQ]);

    return {data}
}
